import { RelationTypeWith } from '../../types';

export const Mother = [
	RelationTypeWith.MOTHER, // 主被保人与投保人是母亲关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 母亲的配偶 -> 父亲, 其他
		[RelationTypeWith.SPOUSE, [RelationTypeWith.FATHER, RelationTypeWith.PARENTS, RelationTypeWith.MOTHER]],
		// 母亲的子女 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.CHILD, [RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]],
		// 母亲的父母 -> 祖辈, 其他
		[
			RelationTypeWith.PARENTS,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 母亲的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 母亲的孙辈 -> 子女, 其他
		[
			RelationTypeWith.GRAND_CHILDREN,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 母亲的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 母亲的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 母亲的法定继承人 -> 自己, 兄弟姐妹, 子女, 孙辈, 祖辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.FATHER,
				RelationTypeWith.PARENTS,
				RelationTypeWith.SELF,
				RelationTypeWith.SIBLING,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 母亲的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 母亲的丈夫 -> 母亲, 父母, 其他
		[RelationTypeWith.HUSBAND, [RelationTypeWith.FATHER, RelationTypeWith.PARENTS, RelationTypeWith.OTHER]],
		// 母亲的儿子 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.SON, [RelationTypeWith.SIBLING, RelationTypeWith.OTHER, RelationTypeWith.SELF]],
		// 母亲的女儿 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.DAUGHTER, [RelationTypeWith.SIBLING, RelationTypeWith.OTHER, RelationTypeWith.SELF]],
		// 母亲的父亲 -> 外祖父, 祖辈, 其他
		[
			RelationTypeWith.FATHER,
			[RelationTypeWith.GRAND_PARENT, RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER]
		],
		// 母亲的母亲 -> 外祖母, 祖辈, 其他
		[
			RelationTypeWith.MOTHER,
			[RelationTypeWith.GRAND_PARENT, RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
		],
		// 母亲的孙子 -> 儿子, 其他
		[RelationTypeWith.GRAND_SON_PARTRILOCAL, [RelationTypeWith.SON, RelationTypeWith.OTHER]],
		// 母亲的孙女 -> 女儿, 其他
		[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, [RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]],
		// 母亲的外孙 -> 儿子, 其他
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, [RelationTypeWith.SON, RelationTypeWith.OTHER]],
		// 母亲的外孙女 -> 女儿, 其他
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, [RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]],
		// 母亲的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 母亲的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 母亲的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 母亲的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.MOTHER, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
