import { Utils } from '../../common';
import { PredefinedCheckRuleOptions } from '../../types';

export const NumericRange = (options: PredefinedCheckRuleOptions): boolean => {
	let { value, params } = options;
	if (Utils.isEmpty(value) || Utils.isEmpty(params)) {
		// 值为空, 不需要比较, 通过校验
		return true;
	}

	let min = Number.NEGATIVE_INFINITY,
		max = Number.POSITIVE_INFINITY,
		includeMax = true,
		includeMin = true;

	const defs = params.match(/^(\[|\()?(\d{1,})?,(\d{1,})?(\]|\)?)$/);
	if (defs == null) {
		throw new Error(`Parameters[${params}] is incorrect.`);
	}
	includeMin = defs[1] !== '(';
	if (defs[2]) {
		min = Number(defs[2]);
	}
	if (defs[3]) {
		max = Number(defs[3]);
	}
	includeMax = defs[4] !== ')';

	if (includeMin) {
		// 包括最小值
		if (value < min) {
			return false;
		}
	} else if (value <= min) {
		// 不包括最小值
		return false;
	}

	// 最大值按天计算
	if (includeMax) {
		// 包括最大值
		if (value > max) {
			return false;
		}
	} else if (value >= max) {
		// 不包括最大值
		return false;
	}

	return true;
};
