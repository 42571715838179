import { Utils } from '../../common';
import { PredefinedCheckRuleOptions, RelationTypeWith } from '../../types';
import { RelationRules, RelationNames } from '..';
import { RelationGenders, GenderByRelation, asGender } from '../../data-model/relationship';

/**
 * relation with policy holder and main insured
 */
export const RelationWithPHAndMI = (options: PredefinedCheckRuleOptions): boolean | string => {
	const { root, model, value, params = {} } = options;
	const { relationPropName = 'extensionData.relationWithInsured' } = params;

	const relationWithPolicyHolderOfMainInsured =
		root['insureds[!extensionData.mainInsured=Y].participants[!customerType=2].extensionData.relationWithPH'];
	const myRelationWithPolicyHolder = value;
	const myRelationWithMainInsured = model[relationPropName];
	if (Utils.isEmpty(myRelationWithMainInsured) || Utils.isEmpty(myRelationWithPolicyHolder)) {
		// 附加被保人与主被保人的关系未确定, 不需要校验
		// 附加被保人与投保人的关系未确定, 不需要校验
		return true;
	}
	const genderByRelationWithMainInsured = RelationGenders.get(myRelationWithMainInsured) || GenderByRelation.UNKNOWN;
	const genderByRelationWithPolicyHolder =
		RelationGenders.get(myRelationWithPolicyHolder) || GenderByRelation.UNKNOWN;
	// 性别校验
	if (
		genderByRelationWithMainInsured + genderByRelationWithPolicyHolder ===
		GenderByRelation.FEMALE + GenderByRelation.MALE
	) {
		return `与主被保人关系${RelationNames.get(myRelationWithMainInsured)}, 与投保人关系${RelationNames.get(
			myRelationWithPolicyHolder
		)}性别不一致.`;
	}

	if (genderByRelationWithMainInsured !== GenderByRelation.UNKNOWN) {
		// 与主被保人关系能够明确性别
		const gender = asGender(genderByRelationWithMainInsured);
		const genderOfPolicyHolder = root['policyHolder.gender'];
		switch (myRelationWithPolicyHolder) {
			case RelationTypeWith.SELF:
				// eslint-disable-next-line
				if (gender != genderOfPolicyHolder) {
					return `与主被保人关系${RelationNames.get(
						myRelationWithMainInsured
					)}, 与投保人关系${RelationNames.get(myRelationWithPolicyHolder)}性别不一致.`;
				}
				break;
			case RelationTypeWith.SPOUSE:
				// eslint-disable-next-line
				if (gender == genderOfPolicyHolder) {
					return `与主被保人关系${RelationNames.get(
						myRelationWithMainInsured
					)}, 与投保人关系${RelationNames.get(myRelationWithPolicyHolder)}性别相同.`;
				}
				break;
		}
	}

	if (Utils.isEmpty(relationWithPolicyHolderOfMainInsured)) {
		// 主被保人与投保人的关系未确定, 不需要校验
		return true;
	}
	// eslint-disable-next-line
	if (relationWithPolicyHolderOfMainInsured == RelationTypeWith.SELF) {
		// 主被保人与投保人的关系是本人, 不需要校验
		// eslint-disable-next-line
		return myRelationWithMainInsured == myRelationWithPolicyHolder
			? true
			: '投保人即主被保人, 与投保人/与主被保人关系应一致.';
	}

	const rules = RelationRules.get(relationWithPolicyHolderOfMainInsured);
	if (Utils.isUndefined(rules)) {
		// 没有定义规则, 不需要校验
		return true;
	}

	const relationTypes = Utils.toArray(rules.get(myRelationWithMainInsured));
	if (relationTypes.length === 0) {
		// 没有定义规则, 不需要校验
		return true;
	}

	if (!relationTypes.includes(myRelationWithPolicyHolder)) {
		// 选择的值没有在合法范围内
		return `与主被保人关系${RelationNames.get(myRelationWithMainInsured)}, 与投保人关系${RelationNames.get(
			myRelationWithPolicyHolder
		)}不能共存.`;
	}

	return true;
};
