import { RelationTypeWith } from '../../types';

export const Parents = [
	RelationTypeWith.PARENTS, // 主被保人与投保人是父母关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 父母的配偶 -> 父母, 其他
		[RelationTypeWith.SPOUSE, [RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.MOTHER]],
		// 父母的子女 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.CHILD, [RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]],
		// 父母的父母 -> 祖辈, 其他
		[
			RelationTypeWith.PARENTS,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 父母的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 父母的孙辈 -> 子女, 其他
		[
			RelationTypeWith.GRAND_CHILDREN,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 父母的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 父母的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 父母的法定继承人 -> 自己, 兄弟姐妹, 子女, 孙辈, 祖辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SIBLING,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 父母的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 父母的妻子 -> 母亲, 父母, 其他
		[RelationTypeWith.WIFE, [RelationTypeWith.MOTHER, RelationTypeWith.PARENTS, RelationTypeWith.OTHER]],
		// 父母的丈夫 -> 父亲, 父母, 其他
		[RelationTypeWith.HUSBAND, [RelationTypeWith.FATHER, RelationTypeWith.PARENTS, RelationTypeWith.OTHER]],
		// 父母的儿子 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.SON, [RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]],
		// 父母的女儿 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.DAUGHTER, [RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]],
		// 父母的父亲 -> 祖父, 外祖父, 祖辈, 其他
		[
			RelationTypeWith.FATHER,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 父母的母亲 -> 祖母, 外祖母, 祖辈, 其他
		[
			RelationTypeWith.MOTHER,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 父母的孙子 -> 儿子, 其他
		[RelationTypeWith.GRAND_SON_PARTRILOCAL, [RelationTypeWith.SON, RelationTypeWith.OTHER]],
		// 父母的孙女 -> 女儿, 其他
		[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, [RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]],
		// 父母的外孙 -> 儿子, 其他
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, [RelationTypeWith.SON, RelationTypeWith.OTHER]],
		// 父母的外孙女 -> 女儿, 其他
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, [RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]],
		// 父母的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 父母的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 父母的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 父母的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.PARENTS, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
