import { RelationTypeWith } from '../../types';

export const GrandParent = [
	RelationTypeWith.GRAND_PARENT, // 主被保人与投保人是祖辈关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 祖辈的配偶 -> 祖辈, 其他
		[
			RelationTypeWith.SPOUSE,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 祖辈的子女 -> 父母, 其他
		[
			RelationTypeWith.CHILD,
			[RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.MOTHER, RelationTypeWith.OTHER]
		],
		// 祖辈的父母 -> 其他
		[RelationTypeWith.PARENTS, RelationTypeWith.OTHER],
		// 祖辈的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 祖辈的孙辈 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.GRAND_CHILDREN, [RelationTypeWith.SIBLING, RelationTypeWith.SELF, RelationTypeWith.OTHER]],
		// 祖辈的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 祖辈的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 祖辈的法定继承人 -> 自己, 兄弟姐妹, 子女, 孙辈, 祖辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SIBLING,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 祖辈的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 祖辈的妻子 -> 祖母, 外祖母, 其他
		[
			RelationTypeWith.WIFE,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 祖辈的丈夫 -> 祖父, 外祖父, 其他
		[
			RelationTypeWith.HUSBAND,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 祖辈的儿子 -> 父亲, 其他
		[RelationTypeWith.SON, [RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.OTHER]],
		// 祖辈的女儿 -> 母亲, 其他
		[RelationTypeWith.DAUGHTER, [RelationTypeWith.PARENTS, RelationTypeWith.MOTHER, RelationTypeWith.OTHER]],
		// 祖辈的父亲 -> 其他
		[RelationTypeWith.FATHER, RelationTypeWith.OTHER],
		// 祖辈的母亲 -> 其他
		[RelationTypeWith.MOTHER, RelationTypeWith.OTHER],
		// 祖辈的孙子 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_SON_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖辈的孙女 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖辈的外孙 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_SON_MATRIARCHAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖辈的外孙女 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖辈的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 祖辈的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 祖辈的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 祖辈的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.GRAND_PARENT, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
