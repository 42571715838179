import { Consts, Utils } from '../../common';
import { PredefinedCheckRuleOptions } from '../../types';

export const GenderOnRelation = (options: PredefinedCheckRuleOptions): boolean => {
	const { value, params = {}, model } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}

	const { relationPropName = 'extensionData.relationWithInsured' } = params;
	const relation = model[relationPropName];

	switch (relation) {
		case '2':
		case '4':
		case '8':
		case '10':
			return value === Consts.GENDER.MALE;
		case '1':
		case '5':
		case '9':
		case '11':
			return value === Consts.GENDER.FEMALE;
	}
	return true;
};
