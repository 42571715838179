import { Utils } from '../common';
import {
    FilterExpression,
    FilterExpressionSimple,
    ModelDataChangedEvent,
    ModelDataChangeEventListener,
    ParsedIdExpression,
    ParsedIdIndex,
    ParsedIdSimple
} from '../types';
import EventEmitter from 'events';

type ValueChange = {
    changed: boolean;
    oldValue?: any;
};

const PROPERTY_FILTER_PATTERN = /\[.+\]/;
// eslint-disable-next-line
const PROPERTY_FILTER_UNWRAPPER = /[\[\]]/g;
const SIMPLE_ID = 'simple';
const INDEX_ID = 'index';
const EXPRESSION_ID = 'expression';
// 表达式过滤器匹配,
// 两端匹配字母|数字|中括号|空格|小数点
// 操作符匹配=, ==, !=, <>, >, >=, <, <=, ===, !==
// 左端如果取出来的是个数组,可以判断数组长度,中间判断符是  (字母)==或(字母)!=
// eslint-disable-next-line
const FILTER_PATTERN = /^([\w|\.|\[|\]|\s|\/|=|\-|!|&]+?)(={0,2}|>=?|<=?|<>|!={1,2})([\-|\w|\.|\[|\]|\s|\u4e00-\u9fff]+)$/;

enum INJECTED_PROPERTY_NAMES {
    EMITTER = '$$emitter',
    ON = '$$on',
    OFF = '$$off',
    CREATE_EVENT = '$$createEvent',
    FIRE_EVENT = '$$fireEvent',
    MODEL = '$$model',
    PROXIED = '$$proxied',
    SWITCH_MODEL = '$$switchModel'
}

class Modeller {
    /**
     * 根据指定的id, 装载需要被监听的id
     */
    private static findEmittingId(id: string): string {
        const ids = Modeller.parseId(id);
        return ids
            .map(id => {
                switch (id.type) {
                    case INDEX_ID:
                        // 仅监听指定的
                        return `${id.id}[${id.index}]`;
                    case EXPRESSION_ID:
                        // 监听所有
                        return id.id;
                    case SIMPLE_ID:
                    default:
                        return id.id;
                }
            })
            .join('.');
    }
    /**
     * 构造代理对象, 已经被代理过的不会被再次代理
     */
    static asProxied(model: any): any {
        if (Modeller.isProxied(model)) {
            return model;
        }

        const emitter = new EventEmitter().setMaxListeners(Infinity);
        const createEvent = (model: any, prop: string, oldValue: any, newValue: any): ModelDataChangedEvent => {
            return {
                model: model,
                prop: prop,
                oldValue: oldValue,
                newValue: newValue
            };
        };

        // 使用闭包保留目标对象
        // let target = model;
        return new Proxy(model, {
            get: function (obj, prop) {
                if (Utils.isSymbol(prop)) {
                    return model[prop];
                }

                switch (prop) {
                    case INJECTED_PROPERTY_NAMES.MODEL:
                        return model;
                    case INJECTED_PROPERTY_NAMES.PROXIED:
                        return true;
                    case INJECTED_PROPERTY_NAMES.EMITTER:
                        return emitter;
                    case INJECTED_PROPERTY_NAMES.ON:
                        return function (this: any, prop: string, listener: ModelDataChangeEventListener): any {
                            // 避免重复监听, 加监听器的时候判断一下对于同一个属性是否已经监听过
                            const id = Modeller.findEmittingId(prop);
                            const exists = emitter.listeners(id) as Function[];
                            if (!exists.includes(listener)) {
                                emitter.on(id, listener);
                            }

                            return this;
                        };
                    case INJECTED_PROPERTY_NAMES.OFF:
                        return function (this: any, prop: string, listener: ModelDataChangeEventListener): any {
                            emitter.off(Modeller.findEmittingId(prop), listener);
                            return this;
                        };
                    case INJECTED_PROPERTY_NAMES.CREATE_EVENT:
                        return createEvent;
                    case INJECTED_PROPERTY_NAMES.FIRE_EVENT:
                        return function (this: any, prop: string, oldValue: any, newValue: any): any {
                            emitter.emit(Modeller.findEmittingId(prop), createEvent(model, prop, oldValue, newValue));
                            return this;
                        };
                    case INJECTED_PROPERTY_NAMES.SWITCH_MODEL:
                        // IMPORTANT 更换模型, 谨慎使用. 会把当前模型上所有的内容全部删除掉, 然后使用新模型浅复制到当前模型
                        // IMPORTANT 因此更换模型后, 当前模型地址不变. 但层级属性的地址会全部改变
                        // IMPORTANT 只有在policy api交互的时候才可以使用, 否则后果自负
                        return function (newModel: any): any {
                            if (newModel == null) {
                                throw new Error('Model to switch cannot be null.');
                            }
                            Object.keys(model).forEach(key => delete model[key]);
                            Object.assign(model, newModel);
                            return model;
                        };
                    default:
                        return Modeller.getValueFromModel(model, prop as string);
                }
            },
            set: function (obj: any, prop: string, value: any): boolean {
                const result = Modeller.setValueToModel(model, prop, value);
                if (result.changed) {
                    emitter.emit(Modeller.findEmittingId(prop), createEvent(model, prop, result.oldValue, value));
                }
                return true;
            },
            deleteProperty: function (obj: any, prop: string): boolean {
                if (prop in model) {
                    const oldValue = model[prop];
                    emitter.emit(Modeller.findEmittingId(prop), createEvent(model, prop, oldValue, undefined));
                    delete model[prop];
                }
                return true;
            },
            // @ts-ignore
            ownKeys: function (obj: any): (string | number | symbol)[] {
                return Reflect.ownKeys(model);
            },
            has: function (obj: any, prop: string): boolean {
                return Reflect.has(model, prop);
            },
            getPrototypeOf: function (target: any): object | null {
                return Reflect.getPrototypeOf(target);
            },
            getOwnPropertyDescriptor: function (obj: any, prop: string): PropertyDescriptor | undefined {
                if (prop.startsWith('$$')) {
                    return {
                        configurable: false,
                        enumerable: false,
                        writable: false
                    };
                }
                return Reflect.getOwnPropertyDescriptor(model, prop);
            }
        });
    }
    /**
     * 判断一个给定的对象是否被代理过
     */
    static isProxied(model: any): boolean {
        if (model == null) {
            return false;
        } else {
            return model.$$proxied === true;
        }
    }
    /**
     * 添加监听器
     */
    static on(model: any, prop: string, listener: ModelDataChangeEventListener): void {
        if (model != null) {
            model.$$on(prop, listener);
        }
    }
    static off(model: any, prop: string, listener: ModelDataChangeEventListener): void {
        if (model != null) {
            model.$$off(prop, listener);
        }
    }
    /**
     * 判断给定的id是否是从root开始计算. 如果以"/"开头, 则认为是.
     */
    static isFromRoot(id: string): boolean {
        return id && id.startsWith('/') ? true : false;
    }

    /**
     * 获取给定的id的真正的id, 如果以"/"开头, 截掉
     */
    static getRealId(id: string): string {
        return Modeller.isFromRoot(id) ? id.substring(1) : id;
    }
    /**
     * 解析过滤器.
     * 过滤分为复杂和简单两种.
     * 1. 简单: 即只有一个表达式, 被解析为一个JSON对象,
     * 2. 复杂: 包含至少两个表达式, 也可能有括号出现. 因此会被解析为简单格式与关系的无限嵌套,
     */
    static parseFilter(filter: string): FilterExpression | FilterExpressionSimple {
        let match;

        // 为了满足多层的解析, 不能直接使用是否包含&&或||,
        // 如'insureds[!extensionData.mainInsured=Y].participants[customerType=2&&extensionData.relationWithPH=1].length!=1'
        // 当只有customerType=2&&extensionData.relationWithPH=1时才当成复杂的表达式解析
        if (!filter.includes('[') && !filter.includes(']') && (filter.includes('&&') || filter.includes('||'))) {
            // 复杂的过滤器, 包含至少两个表达式, 有可能有括号

            // 构建顶层, 假设是and关系, 并且有0个表达式
            const top = {
                type: 'and',
                expressions: []
            } as FilterExpression;

            const stack = [top];
            let currentExpression = '';
            let first = true;

            filter.split('').forEach(ch => {
                if (ch === '(') {
                    // 进入括号, 创建下一层, 但此时还不知道这一层是什么关系
                    const group = {
                        type: 'and',
                        expressions: []
                    } as FilterExpression;

                    // 将新的下一层压到expressions中
                    stack[stack.length - 1].expressions.push(group);
                    // 将现在需要处理的层压到栈底, 之后的表达式就会使用这个层
                    stack.push(group);
                } else if (ch === ')') {
                    // 脱离括号, 本层结束
                    // 有可能前面正好是), 表示括号结束, 而不是表达式
                    if (currentExpression) {
                        stack[stack.length - 1].expressions.push(Modeller.parseFilter(currentExpression));
                    }
                    // 将当前处理的层弹出栈, 结束本次括号. 之后的表达式则会使用上一级的层
                    stack.pop();
                    // 清理掉当前处理的表达式
                    currentExpression = '';
                } else if ((ch === '&' || ch === '|') && first) {
                    if (ch === '|') {
                        // 假设关系不成立, 改成or
                        stack[stack.length - 1].type = 'or';
                    }
                    // 遇到了第一个&或者|
                    first = false;
                    // 前一个表达式结束
                    // 有可能前面正好是), 表示括号结束, 而不是表达式
                    if (currentExpression) {
                        stack[stack.length - 1].expressions.push(Modeller.parseFilter(currentExpression));
                    }
                    // 清理掉当前处理的表达式
                    currentExpression = '';
                } else if ((ch === '&' || ch === '|') && !first) {
                    // 遇到了第二个&或者|
                    first = true;
                    // 什么都别干
                } else {
                    // 累计表达式字符串
                    currentExpression += ch;
                }
            });
            // 处理完字符串, 如果还有表达式没有处理到, 处理一下
            if (currentExpression) {
                stack[stack.length - 1].expressions.push(Modeller.parseFilter(currentExpression));
            }
            return top;
        } else if ((match = filter.match(FILTER_PATTERN)) != null) {
            // 只有一个表达式的过滤器
            return {
                prop: match[1].trim(),
                operator: match[2],
                value: match[3].trim()
            } as FilterExpressionSimple;
        } else {
            throw new Error(`Cannot parse filter[${filter}].`);
        }
    }
    static isSimpleExpression(filter: FilterExpressionSimple | FilterExpression): filter is FilterExpressionSimple {
        // eslint-disable-next-line
        return (filter as any).type == undefined;
    }
    /**
     * 匹配过滤器.
     * 过滤目前仅支持:
     * 1. =和==: 值可以是字符串, 数字和boolean
     * 2. !=和<>: 值可以是字符串, 数字和boolean
     * 3. >: 值必须是数字
     * 4. >=: 值必须是数字
     * 5. <: 值必须是数字
     * 6. <=: 值必须是数字
     */
    static matchFilter(model: any, filter: FilterExpressionSimple | FilterExpression): boolean {
        if (Modeller.isSimpleExpression(filter)) {
            const value = Modeller.getValueFromModel(model, filter.prop);
            const op = filter.operator;
            return Modeller.matchValue(value, op, filter.value);
        } else if (filter.type === 'and') {
            // 复杂类型
            // 只要有一个不符合条件, 则认为不符合
            return !filter.expressions.some(sub => {
                return !Modeller.matchFilter(model, sub);
            });
        } else if (filter.type === 'or') {
            // 复杂类型
            // 只要有一个复合条件, 则认为符合
            return filter.expressions.some(sub => {
                return Modeller.matchFilter(model, sub);
            });
        } else {
            console.error(filter);
            throw new Error('Unsupported filter.');
        }
    }

    static findFilterProp(filter: FilterExpressionSimple | FilterExpression, prop: string): boolean {
        if (Modeller.isSimpleExpression(filter)) {
            return filter.prop === prop;
        } else {
            // 复杂类型
            return filter.expressions.some(sub => {
                return Modeller.findFilterProp(sub, prop);
            });
        }
    }

    static restoreObjFromFilter(obj: any, filter: FilterExpressionSimple | FilterExpression): any {
        if (Modeller.isSimpleExpression(filter)) {
            let value = filter.value === 'true' ? true : filter.value === 'false' ? false : filter.value === 'null' ? null : filter.value === '' ? '' : filter.value;
            return Object.assign(obj, { [filter.prop]: value });
        } else {
            return filter.expressions.reduce((obj, sub) => {
                return Modeller.restoreObjFromFilter(obj, sub);
            }, {});
        }
    }

    /**
     * 判断是否相等
     */
    private static equals(a: any, b: string): boolean {
        const v = b === 'true' ? true : b === 'false' ? false : b === 'null' ? null : b === '' ? '' : b;
        if (v === true) {
            return a === true;
        } else if (v === null || v === false || v === '') {
            return a == null || a === false || a === '';
        } else {
            // eslint-disable-next-line
            return a == b;
        }
    }
    /**
     * 判断强等
     */
    private static forceEquals(a: any, b: string): boolean {
        if (b === 'true') {
            return a === true;
        } else if (b === 'false') {
            return a === false;
        } else if (b === 'null') {
            return a === null || typeof a === 'undefined';
        } else {
            return a === b;
        }
    }
    /**
     * 匹配过滤器.
     * 过滤目前仅支持:
     * 1. =和==: 值可以是字符串, 数字和boolean
     * 2. !=和<>: 值可以是字符串, 数字和boolean
     * 3. >: 值必须是数字
     * 4. >=: 值必须是数字
     * 5. <: 值必须是数字
     * 6. <=: 值必须是数字
     */
    private static matchValue(value: any, op: string, filterValue: string): boolean {
        if (op === '=' || op === '==') {
            return Modeller.equals(value, filterValue);
        } else if (op === '<>' || op === '!=') {
            return !Modeller.equals(value, filterValue);
        } else if (op === '>') {
            return value > +filterValue;
        } else if (op === '<') {
            return value < +filterValue;
        } else if (op === '>=') {
            return value >= +filterValue;
        } else if (op === '<=') {
            return value <= +filterValue;
        } else if (op === '===') {
            return Modeller.forceEquals(value, filterValue);
        } else if (op === '!==') {
            return !Modeller.forceEquals(value, filterValue);
        }
        return true;
    }
    /**
     * 从模型中取值, 属性名不会做任何分析
     */
    private static getFromModel(model: any, id: string): any | null | undefined {
        return model ? model[id] : null;
    }
    /**
     * 将值设置到模型中, 属性名不会做任何分析.
     * 只有在模型存在的时候设置.
     */
    private static setToModel(model: any, id: string, value: any): void {
        if (model) {
            model[id] = value;
        }
    }
    private static getOrCreateByIds(
        model: any,
        ids: (ParsedIdSimple | ParsedIdExpression | ParsedIdIndex)[],
        id: string
    ) {
        return ids.reduce((model: any, idPart, idPartIndex: number) => {
            switch (idPart.type) {
                case INDEX_ID:
                    const array = Modeller.getOrCreateArrayFromModel(model, idPart.id);
                    const length = array.length;
                    const index = idPart.index;
                    if (index < 0 || index > length - 1) {
                        // 越界
                        throw new Error(`Index out of bounds when get value by [${id}]`);
                    } else {
                        // 返回指定位置的对象
                        // 注意已经不是数组, 而是对象本身
                        return array[index];
                    }
                case EXPRESSION_ID:
                    const arrayData = Modeller.getOrCreateArrayFromModel(model, idPart.id).filter(item => {
                        return Modeller.matchFilter(item, idPart.filter);
                    });
                    if (idPart.exact) {
                        if (arrayData.length !== 1) {
                            // 必须是唯一的值, 不能没有, 也不能超过一个
                            throw new Error(`Index out of bounds when get value by [${id}]`);
                        } else {
                            return arrayData[0];
                        }
                    } else {
                        // 过滤出来是数组, 无法精确定位, 抛错
                        throw new Error(`Expression[${id}] is not supported.`);
                    }
                case SIMPLE_ID:
                default:
                    let object = Modeller.getFromModel(model, idPart.id);
                    if (object == null) {
                        object = {};
                        Modeller.setToModel(model, idPart.id, object);
                    }
                    return object;
            }
        }, model);
    }
    /**
     * 从模型中取数组, 属性名不会做任何分析
     * 如果数组不存在, 创建一个空数组填充
     */
    private static getOrCreateArrayFromModel = function (model: any, id: string): any[] {
        let array = Modeller.getFromModel(model, id);
        if (array == null) {
            array = [];
            Modeller.setToModel(model, id, array);
        }
        return array;
    };
    /**
     * 从模型中获取值
     */
    private static getValueFromModel(model: any, id: string): any {
        if (!model && !id) {
            return null;
        }
        const ids = Modeller.parseId(id);
        return ids.reduce((model, idPart) => {
            if (model) {
                switch (idPart.type) {
                    case INDEX_ID:
                        const array = Modeller.getOrCreateArrayFromModel(model, idPart.id);
                        // 如果filter是一个数字, 直接获取相对位置的模型, 没有的话直接抛错.
                        const length = array.length;
                        const index = idPart.index;
                        if (index < 0 || index > length - 1) {
                            // 越界
                            throw new Error(`Index out of bounds when get value by [${id}]`);
                        } else {
                            // 返回指定位置的对象
                            // 注意已经不是数组, 而是对象本身
                            return array[index];
                        }
                    case EXPRESSION_ID:
                        // filter是一段语法
                        let arrayData = Modeller.getOrCreateArrayFromModel(model, idPart.id).filter(item => {
                            return Modeller.matchFilter(item, idPart.filter);
                        });
                        if (idPart.exact) {
                            // testing 测试阶段，影响较大，目前仅针对documents
                            let isDocumentId = Modeller.findFilterProp(idPart.filter, 'documentType');
                            if (!arrayData.length && isDocumentId) {
                                // 如果没有该节点, 按照filter的规则推一个符合标准的节点进去
                                console.log('如果没有', arrayData);
                                console.log('idPart.filter', idPart.filter);
                                let array = Modeller.getOrCreateArrayFromModel(model, idPart.id);
                                const sample = Modeller.restoreObjFromFilter({}, idPart.filter);
                                console.log('sample', sample);
                                console.log('array', array);
                                return array[array.push(sample) - 1];
                            } else if (arrayData.length !== 1) {
                                // 越界
                                // eslint-disable-next-line
                                throw new Error(`Index out of bounds when get value by [${id}]`);
                            } else {
                                return arrayData[0];
                            }
                        } else {
                            return arrayData;
                        }
                    case SIMPLE_ID:
                    default:
                        // 没有指定过滤器
                        return Modeller.getFromModel(model, idPart.id);
                }
            } else {
                // 模型没有, 返回null
                return null;
            }
        }, model);
    }
    static getValue(where: { model: any; root: any }, id: string): any {
        if (where.model == null) {
            return Modeller.getValue({ model: where, root: where }, id);
        } else {
            const model = Modeller.isFromRoot(id) ? where.root : where.model;
            if (Modeller.isProxied(model)) {
                return model[Modeller.getRealId(id)];
            } else {
                return Modeller.getValueFromModel(model, Modeller.getRealId(id));
            }
        }
    }
    /**
     * 设置值到模型当中. 如果没有设置强制执行, 并且与原始值相等, 则返回false.
     * 注意如果强制设置force参数为true, 即便与原始值相等, 也有可能返回已执行
     *
     * @return 返回true表示操作执行了. false表示操作没有执行.
     */
    private static setValueToModel(model: any, id: string, value: any, force: boolean = false): ValueChange {
        let oldValue = Modeller.getValueFromModel(model, id);
        // eslint-disable-next-line
        if (!force && value !== '' && oldValue !== '' && value == oldValue) {
            // 如果值相等, 则忽略设置步骤
            // 如果两个值之中有一个是空串, 则一定需要设置. 防止一个是''和另一个是0, 被判断为不需要设值
            return {
                changed: false
            };
        }

        const ids = Modeller.parseId(id);
        const last = ids.pop();
        const object = Modeller.getOrCreateByIds(model, ids, id);
        Modeller.setToModel(object, last!.id, value);
        return {
            changed: true,
            oldValue
        };
    }
    static setValue(where: { model: any; root: any } | any, id: string, value: any, force: boolean = false): any {
        if (where.model == null) {
            Modeller.setValue({ model: where, root: where }, id, value, force);
        } else {
            const model = Modeller.isFromRoot(id) ? where.root : where.model;
            if (Modeller.isProxied(model)) {
                return (model[Modeller.getRealId(id)] = value);
            } else {
                return Modeller.setValueToModel(model, Modeller.getRealId(id), value, force);
            }
        }
    }
    /**
     * 解析id成为一个id数组
     */
    private static parseId(id: string): (ParsedIdSimple | ParsedIdExpression | ParsedIdIndex)[] {
        // const ids = id.split('.');
        let currentId = '';
        let inExpression = false;

        const ids = [];
        id.split('').forEach(ch => {
            if (ch !== '.') {
                currentId += ch;
            }
            if (ch === '[') {
                inExpression = true;
            } else if (ch === ']') {
                inExpression = false;
            } else if (ch === '.' && inExpression) {
                currentId += ch;
            } else if (ch === '.' && !inExpression) {
                // @ts-ignore
                ids.push(currentId);
                currentId = '';
            }
        });
        // @ts-ignore
        ids.push(currentId);

        return ids.map(id => {
            // @ts-ignore
            const matcher = id.match(PROPERTY_FILTER_PATTERN);
            if (matcher) {
                // 去掉过滤器的方括号
                const filter = matcher[0].replace(PROPERTY_FILTER_UNWRAPPER, '');
                // 获取真正的属性名称
                // @ts-ignore
                const realId = id.substring(0, matcher.index);
                if (!isNaN(+filter)) {
                    // 如果过滤器是数字
                    return {
                        id: realId,
                        type: INDEX_ID,
                        index: +filter
                    } as ParsedIdIndex;
                } else if (filter.startsWith('!')) {
                    // 需要取得过滤后唯一的值结果, 非数组
                    return {
                        id: realId,
                        type: EXPRESSION_ID,
                        filter: Modeller.parseFilter(filter.substring(1).trim()),
                        exact: true
                    } as ParsedIdExpression;
                } else {
                    // 需要取得过滤后的数组
                    return {
                        id: realId,
                        type: EXPRESSION_ID,
                        filter: Modeller.parseFilter(filter),
                        exact: false
                    } as ParsedIdExpression;
                }
            } else {
                // 没有匹配到过滤条件
                return {
                    id: id,
                    type: SIMPLE_ID
                } as ParsedIdSimple;
            }
        });
    }
}

export default Modeller;
