import { Utils } from '../../common';
import {
	CheckRuleRegexpPredefinedSet,
	PredefinedCheckRuleOptions
} from '../../types';
import PrefefinedRegexps from './predefined-regexp';

const testByRegexp = (regexp: string | RegExp, value: string): boolean => {
	if (Utils.isRegExp(regexp)) {
		// 指定了正则表达式
		return regexp.test(value);
	} else if (Utils.isString(regexp)) {
		// 指定了预置正则表达式名称
		const predefined = PrefefinedRegexps.find(
			regexp.toUpperCase() as CheckRuleRegexpPredefinedSet
		);
		return predefined.test(value);
	} else {
		console.error(regexp);
		throw new Error('Unsupported regexp definition.');
	}
};

export const Regexp = (options: PredefinedCheckRuleOptions): boolean => {
	const { value, params } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}
	if (Utils.isObject(params)) {
		const { regexp, contrary } = params as any;
		// 是否需要翻转结果
		return contrary
			? !testByRegexp(regexp, value)
			: testByRegexp(regexp, value);
	} else {
		return testByRegexp(params, value);
	}
};
