import { Consts, Utils } from '../../common';
import { DateFNS } from '../../common/3rd';
import { IdNumberMatchCheckType, PredefinedCheckRuleOptions } from '../../types';

const asGender = (value: number): string => (value % 2 === 1 ? 'M' : 'F');

export const IdNumberMatch = (options: PredefinedCheckRuleOptions): boolean | string => {
	let { value, params, model } = options;
	if (Utils.isEmpty(value)) {
		return true;
	}

	const { type, prefix, valueId } = params;
	if (valueId) {
		value = model[valueId];
		if (Utils.isEmpty(value)) {
			return true;
		}
	}

	let idTypePropName = 'idType';
	let idNoPropName = 'idNo';
	if (!Utils.isEmpty(prefix)) {
		idTypePropName = `${prefix}.${idTypePropName}`;
		idNoPropName = `${prefix}.${idNoPropName}`;
	}
	
	const idNo = model[idNoPropName];
	if (Utils.isEmpty(idNo)) {
		// 没有填写身份证号码, 不需要校验
		return true;
	}

	const idType = model[idTypePropName];
	// eslint-disable-next-line
	if (idType == Consts.ID_TYPE.ID_CARD) {
		switch (type) {
			case IdNumberMatchCheckType.DATE_OF_BIRTH:
				const dob = DateFNS.format(value, 'YYYYMMDD');
				if (idNo.length === 18) {
					return dob === idNo.substring(6, 14) ? true : '出生日期与身份证号码不符.';
				} else if (idNo.length === 15) {
					return dob === `19${idNo.substring(6, 12)}` ? true : '出生日期与身份证号码不符.';
				} else {
					// 身份证号码不正确, 不需要校验
					return true;
				}
			case IdNumberMatchCheckType.GENDER:
				if (idNo.length === 18) {
					return value === asGender(idNo.substring(16, 17)) ? true : '性别与身份证号码不符.';
				} else if (idNo.length === 15) {
					return value === asGender(idNo.substring(14, 15)) ? true : '性别与身份证号码不符.';
				} else {
					// 身份证号码不正确, 不需要校验
					return true;
				}
			default:
				throw new Error(`Unsupported id number match check type[${type}].`);
		}
		// eslint-disable-next-line
	}else if(idType == Consts.ID_TYPE.FAMILY_REGISTER){
		switch (type) {
			case IdNumberMatchCheckType.DATE_OF_BIRTH:
				const dob = DateFNS.format(value, 'YYYYMMDD');
				if (idNo.length === 18) {
					return dob === idNo.substring(6, 14) ? true : '出生日期与户口本号码不符.';
				} else if (idNo.length === 15) {
					return dob === `19${idNo.substring(6, 12)}` ? true : '出生日期与户口本号码不符.';
				} else {
					// 身份证号码不正确, 不需要校验
					return true;
				}
			case IdNumberMatchCheckType.GENDER:
				if (idNo.length === 18) {
					return value === asGender(idNo.substring(16, 17)) ? true : '性别与户口本号码不符.';
				} else if (idNo.length === 15) {
					return value === asGender(idNo.substring(14, 15)) ? true : '性别与户口本号码不符.';
				} else {
					// 身份证号码不正确, 不需要校验
					return true;
				}
			default:
				throw new Error(`Unsupported id number match check type[${type}].`);
		}
	}
	// 不是身份证或户口本
	return true;


	
};
