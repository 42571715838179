import { RelationTypeWith } from '../../types';

export const Spouse = [
	RelationTypeWith.SPOUSE, // 主被保人与投保人是配偶关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 配偶的配偶 -> 自己
		[RelationTypeWith.SPOUSE, RelationTypeWith.SELF],
		// 配偶的子女 -> 自己的子女, 其他
		[
			RelationTypeWith.CHILD,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 配偶的父母 -> 其他
		[RelationTypeWith.PARENTS, RelationTypeWith.OTHER],
		// 配偶的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 配偶的孙辈 -> 自己的孙辈, 其他
		[
			RelationTypeWith.GRAND_CHILDREN,
			[
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 配偶的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 配偶的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 配偶的法定继承人 -> 自己的法定继承人, 自己, 子女, 孙辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.HEIR_AT_LAW,
				RelationTypeWith.SELF,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 配偶的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 配偶的妻子 -> 自己, 其他
		[RelationTypeWith.WIFE, [RelationTypeWith.SELF, RelationTypeWith.OTHER]],
		// 配偶的丈夫 -> 自己, 其他
		[RelationTypeWith.HUSBAND, [RelationTypeWith.SELF, RelationTypeWith.OTHER]],
		// 配偶的儿子 -> 儿子, 子女, 其他
		[RelationTypeWith.SON, [RelationTypeWith.SON, RelationTypeWith.CHILD, RelationTypeWith.OTHER]],
		// 配偶的女儿 -> 女儿, 子女, 其他
		[RelationTypeWith.DAUGHTER, [RelationTypeWith.DAUGHTER, RelationTypeWith.CHILD, RelationTypeWith.OTHER]],
		// 配偶的父亲 -> 其他
		[RelationTypeWith.FATHER, RelationTypeWith.OTHER],
		// 配偶的母亲 -> 其他
		[RelationTypeWith.MOTHER, RelationTypeWith.OTHER],
		// 配偶的孙子 -> 孙子, 孙辈, 其他
		[
			RelationTypeWith.GRAND_SON_PARTRILOCAL,
			[RelationTypeWith.GRAND_SON_PARTRILOCAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 配偶的孙女 -> 孙女, 孙辈, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
			[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 配偶的外孙 -> 外孙, 孙辈, 其他
		[
			RelationTypeWith.GRAND_SON_MATRIARCHAL,
			[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 配偶的外孙女 -> 外孙女, 孙辈, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
			[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 配偶的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 配偶的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 配偶的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 配偶的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.SPOUSE, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
