import { Codes, Consts, Utils } from '../../common';
import { DateFNS } from '../../common/3rd';
import { PredefinedCheckRuleOptions } from '../../types';

export const PermanentResidentPermit = (options: PredefinedCheckRuleOptions): boolean | string => {
    let { value, params, model } = options;

    const prefix = params;
    const idType = Utils.isEmpty(prefix) ? model[`idType`] : model[`${prefix}.idType`];
    // eslint-disable-next-line
    if (idType != Consts.ID_TYPE.PERMANENT_RESIDENT_PERMIT) {
        // 不是永久居住证
        return true;
    }
    if (Utils.isEmpty(value)) {
        return true;
    }

    const pass = ((): true | string => {
        if (value.length !== 15 && value.length !== 18) {
            return '请输入正确的证件号码.';
        }
        if(value.length == 15) {

            if (!new RegExp('^[A-Z]{3}[0-9]{12}$').test(value)) {
                return '请输入正确的证件号码.';
            }

            // 校验国籍是否正确
            const nationality = value.substring(0, 3);
            if (!Codes.NATIONALITY.map(code => code.v).includes(nationality)) {
                return '请输入正确的证件号码.';
            }
        }

        if(value.length == 18) {
            if (!new RegExp('^9[0-9]{16}[0-9X]{1}$').test(value)) {
                return '请输入正确的证件号码';
            }
            const dateOfBirth = Utils.isEmpty(prefix) ? model[`dateOfBirth`] : model[`${prefix}.dateOfBirth`];

            if (!Utils.isEmpty(dateOfBirth) && DateFNS.format(dateOfBirth, 'YYYYMMDD') !== value.substring(6, 14)) {
                return '请输入正确的证件号码.';
            }
            const gender = Utils.isEmpty(prefix) ? model[`gender`] : model[`${prefix}.gender`];
            if ((value.substring(16, 17) % 2 === 1 && gender == 'F') || (value.substring(16, 17) % 2 !== 1 && gender == 'M')) {
                return '请输入正确的证件号码.';

            }

            // 18位身份证需要验证最后一位校验位
            value = value.toUpperCase();
            value = value.split('');
            //∑(ai×Wi)(mod 11)
            //加权因子
            const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            //校验位
            const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
            let sum = 0;
            let ai = 0;
            let wi = 0;
            for (let i = 0; i < 17; i++) {
                ai = value[i];
                wi = factor[i];
                sum += ai * wi;
            }
            // eslint-disable-next-line
            if (parity[sum % 11] != value[17]) {
                return '请输入正确的证件号码.';
            }
        }
        return true;
    })();

    return pass;
};
