import { PredefinedCheckRuleOptions } from '../../types';

export const ChinaPassport = (options: PredefinedCheckRuleOptions): boolean | string => {
	// let { value, params, model } = options;
	// const idType = model[params];
	// if (idType != Consts.ID_TYPE.PASSPORT) {
	// 	// 不是护照
	// 	return true;
	// }
	// if (Utils.isEmpty(value)) {
	// 	return true;
	// }

	// return PrefefinedRegexps.find(CheckRuleRegexpPredefinedSet.CHINA_PASSPORT).test(value)
	// 	? true
	// 	: '请输入正确的护照号码.';
	return true;
};
