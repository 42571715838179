import { CodeItem } from '../../types';
/**
 * 银行信息
 */
export const BANK_JSON = {
	'001': '中国人民银行会计营业部门',
	'011': '中国人民银行国家金库',
	'100': '中国邮政储蓄银行',
	'102': '中国工商银行',
	'103': '中国农业银行',
	'104': '中国银行',
	'105': '中国建设银行',
	'1402': '昆山农村信用合作社联合社',
	'1403': '常熟农村商业银行',
	'1404': '深圳市农村信用合作社联合社',
	'1405': '广州农村信用合作社',
	'1408': '顺德农信社',
	'1409': '昆明市农村信用联社',
	'1411': '徐州市郊农村信用合作联社',
	'1412': '江阴农村商业银行',
	'1413': '重庆市农村信用社联合社',
	'1414': '山东农村信用联合社',
	'1415': '东莞农村信用合作社',
	'1416': '张家港市农村商业银行',
	'1417': '福建省农村信用社联合社',
	'1418': '北京农联社',
	'1419': '天津市农村信用社',
	'1420': '宁波鄞州农村合作银行',
	'1421': '佛山市三水区农村信用合作社',
	'1422': '成都农信社',
	'1423': '沧州农信社',
	'1424': '江苏农信社',
	'1425': '江门市新会农信社',
	'1426': '高要市农村信用合作社联社',
	'1427': '佛山市禅城区农村信用联社',
	'1429': '浙江省农村信用社联社',
	'1430': '江苏东吴农村商业银行',
	'1431': '珠海农村信用合作联社',
	'1432': '中山市农村信用合作社',
	'1433': '太仓农村商业银行',
	'1434': '尧都区农村信用合作社联社',
	'1435': '武进农村商业银行',
	'1436': '贵州省农村信用社联合社',
	'1437': '江苏锡州农村商业银行',
	'1438': '湖南省农村信用社联合社',
	'1442': '陕西省农村信用社联合社',
	'1439': '江西省农村信用社联合社',
	'201': '国家开发银行',
	'202': '中国进出口银行',
	'203': '中国农业发展银行',
	'301': '交通银行',
	'302': '中信银行', //中信银行，原来叫中信实业银行
	'303': '中国光大银行',
	'304': '华夏银行',
	'305': '中国民生银行',
	'306': '广东发展银行',
	'307': '平安银行', //平安银行,原来叫深圳发展银行
	'308': '招商银行',
	'309': '兴业银行',
	'310': '上海浦东发展银行',
	'313': '城市商业银行',
	'314': '农村商业银行',
	'317': '渤海银行',
	'401': '上海银行',
	'402': '农村信用社',
	'403': '北京银行',
	'405': '福州市商业银行',
	'406': '长春市商业银行',
	'408': '宁波市商业银行',
	'410': '深圳市商业银行',
	'411': '焦作市商业银行',
	'412': '温州商业银行',
	'413': '广州市商业银行',
	'414': '武汉市商业银行',
	'417': '沈阳市商业银行',
	'418': '洛阳市商业银行',
	'419': '辽阳市商业银行',
	'420': '大连商业银行',
	'421': '苏州市商业银行',
	'423': '杭州商业银行',
	'424': '南京市商业银行',
	'425': '东莞市商业银行',
	'426': '金华市商业银行',
	'427': '乌鲁木齐市商业银行',
	'428': '绍兴商业银行',
	'429': '成都商业银行',
	'430': '抚顺市商业银行',
	'432': '宜昌市商业银行',
	'434': '天津市商业银行',
	'435': '郑州商业银行',
	'436': '银川市商业银行',
	'437': '珠海市商业银行',
	'440': '徽商银行',
	'441': '重庆市商业银行',
	'442': '哈尔滨市商业银行',
	'443': '贵阳市商业银行',
	'444': '西安市商业银行',
	'446': '丹东商行',
	'447': '兰州市商业银行',
	'450': '青岛商行',
	'451': '吉林市商业银行',
	'452': '南通市商业银行',
	'453': '扬州市商业银行',
	'454': '九江市商业银行',
	'456': '鞍山市商业银行',
	'458': '西宁市商业银行',
	'459': '台州市商业银行',
	'461': '长沙市商业银行',
	'464': '泉州市商业银行',
	'465': '营口市商业银行',
	'466': '昆明商业银行',
	'467': '阜新市商业银行',
	'468': '常州商业银行',
	'469': '淮安市商业银行',
	'470': '嘉兴市商业银行',
	'472': '廊坊市商业银行',
	'473': '泰隆城市信用社',
	'474': '呼市商业银行',
	'478': '南宁市商业银行',
	'479': '包头市商业银行',
	'480': '连云港市商业银行',
	'483': '攀枝花市商业银行',
	'485': '绵阳市商业银行',
	'486': '泸州市商业银行',
	'487': '大同市商业银行',
	'488': '三门峡市城市信用社',
	'489': '湛江市商业银行',
	'491': '桂林市商业银行',
	'492': '大庆市商业银行',
	'493': '靖江市长江城市信用社',
	'494': '徐州市商业银行',
	'495': '柳州市商业银行',
	'496': '中外合资.南充市商业银行',
	'497': '莱芜市商业银行',
	'498': '德阳市商业银行',
	'501': '香港上海汇丰银行',
	'502': '东亚银行',
	'503': '南洋商业银行',
	'504': '恒生银行',
	'505': '中银香港',
	'506': '集友银行',
	'507': '廖创兴银行',
	'508': '亚洲商业银行',
	'509': '道亨银行',
	'510': '永亨银行',
	'531': '花旗银行',
	'532': '美国银行',
	'533': '摩根大通银行',
	'534': '建东银行',
	'535': '美一银行',
	'536': '纽约银行',
	'561': '东京三菱银行',
	'562': '日联银行',
	'563': '三井住友银行',
	'564': '瑞穗实业银行',
	'565': '山口银行',
	'591': '韩国外换银行',
	'592': '朝兴银行',
	'593': '友利银行',
	'594': '韩国产业银行',
	'595': '新韩银行',
	'596': '韩国中小企业银行',
	'597': '韩亚银行',
	'611': '马来亚银行',
	'616': '首都银行及信托公司',
	'621': '华侨银行',
	'622': '大华银行',
	'623': '新加坡发展银行',
	'631': '盘古银行',
	'632': '泰京银行',
	'633': '泰华农民银行',
	'641': '奥地利中央合作银行',
	'651': '比利时联合银行',
	'652': '比利时富通银行',
	'661': '荷兰银行',
	'662': '荷兰商业银行',
	'663': '荷兰万贝银行',
	'671': '渣打银行',
	'691': '法国兴业银行',
	'692': '法国巴黎银行',
	'693': '东方汇理银行',
	'694': '法国里昂信贷银行',
	'695': '法国外贸银行',
	'711': '德累斯顿银行',
	'712': '德意志银行',
	'713': '德国商业银行',
	'714': '西德意志银行',
	'715': '巴伐利亚州银行',
	'731': '罗马银行',
	'732': '意大利联合商业银行',
	'741': '瑞士信贷第一波士顿银行',
	'751': '丰业银行',
	'752': '蒙特利尔银行',
	'761': '澳新银行',
	'766': '葡国储蓄信贷银行',
	'771': '珠海南通银行',
	'772': '宁波国际银行',
	'773': '新联商业银行',
	'774': '协和银行',
	'775': '德富泰银行有限公司',
	'776': '荷兰合作银行(中国)有限公司',
	'781': '厦门国际银行',
	'782': '上海—巴黎国际银行',
	'783': '福建亚洲银行',
	'784': '浙江商业银行',
	'785': '华商银行',
	'786': '青岛国际银行',
	'787': '华一银行',
	'901': '中央国债登记结算有限责任公司',
	'902': '中国人民银行公开市场操作室',
	'903': '中国银行间外汇交易中心',
	'904': '城市商业银行资金清算中心',
	'521': '江苏银行'
} as any;

//转化为codes
export const BankInfo = Object.keys(BANK_JSON).reduce((bank: CodeItem[], key: string) => {
	let tmp = {} as CodeItem;
	tmp.v = key;
	tmp.l = BANK_JSON[key];
	bank.push(tmp);
	return bank;
}, []) as CodeItem[];
