import { RelationTypeWith } from '../../types';

export const Employ = [
	RelationTypeWith.EMPLOY, // 主被保人与投保人是雇佣关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 雇员的配偶
		[RelationTypeWith.SPOUSE, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的子女
		[RelationTypeWith.CHILD, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的父母
		[RelationTypeWith.PARENTS, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的(外)祖父母
		[RelationTypeWith.GRAND_PARENT, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的孙辈
		[RelationTypeWith.GRAND_CHILDREN, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的养父母
		[RelationTypeWith.FOSTER_PARENT, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的法定继承人
		[RelationTypeWith.HEIR_AT_LAW, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的兄弟姐妹
		[RelationTypeWith.SIBLING, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的妻子
		[RelationTypeWith.WIFE, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的丈夫
		[RelationTypeWith.HUSBAND, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的儿子
		[RelationTypeWith.SON, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的女儿
		[RelationTypeWith.DAUGHTER, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的父亲
		[RelationTypeWith.FATHER, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的母亲
		[RelationTypeWith.MOTHER, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的孙子
		[RelationTypeWith.GRAND_SON_PARTRILOCAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的孙女
		[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的外孙
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的外孙女
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的祖父
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的祖母
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的外祖父
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 雇员的外祖母
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]]
	])
] as [RelationTypeWith.EMPLOY, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
