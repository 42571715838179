import { RelationTypeWith } from '../../types';

export const GrandChildren = [
	RelationTypeWith.GRAND_CHILDREN, // 主被保人与投保人是孙辈关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 孙辈的配偶 -> 其他
		[RelationTypeWith.SPOUSE, RelationTypeWith.OTHER],
		// 孙辈的子女 -> 其他
		[RelationTypeWith.CHILD, RelationTypeWith.OTHER],
		// 孙辈的父母 -> 子女, 其他
		[
			RelationTypeWith.PARENTS,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 孙辈的(外)祖父母 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_PARENT,
			[
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.WIFE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.OTHER
			]
		],
		// 孙辈的孙辈 -> 其他
		[RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER],
		// 孙辈的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 孙辈的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 孙辈的法定继承人 -> 自己, 子女, 孙辈, 祖辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.WIFE,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 孙辈的兄弟姐妹 -> 孙辈, 其他
		[
			RelationTypeWith.SIBLING,
			[
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 孙辈的妻子 -> 其他
		[RelationTypeWith.WIFE, RelationTypeWith.OTHER],
		// 孙辈的丈夫 -> 其他
		[RelationTypeWith.HUSBAND, RelationTypeWith.OTHER],
		// 孙辈的儿子 -> 其他
		[RelationTypeWith.SON, RelationTypeWith.OTHER],
		// 孙辈的女儿 -> 其他
		[RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER],
		// 孙辈的父亲 -> 儿子, 其他
		[RelationTypeWith.FATHER, [RelationTypeWith.SON, RelationTypeWith.CHILD, RelationTypeWith.OTHER]],
		// 孙辈的母亲 -> 女儿, 其他
		[RelationTypeWith.MOTHER, [RelationTypeWith.DAUGHTER, RelationTypeWith.CHILD, RelationTypeWith.OTHER]],
		// 孙辈的孙子 -> 其他
		[RelationTypeWith.GRAND_SON_PARTRILOCAL, RelationTypeWith.OTHER],
		// 孙辈的孙女 -> 其他
		[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 孙辈的外孙 -> 其他
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.OTHER],
		// 孙辈的外孙女 -> 其他
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 孙辈的祖父 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.HUSBAND, RelationTypeWith.SPOUSE, RelationTypeWith.OTHER]
		],
		// 孙辈的祖母 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.WIFE, RelationTypeWith.SPOUSE, RelationTypeWith.OTHER]
		],
		// 孙辈的外祖父 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
			[RelationTypeWith.SELF, RelationTypeWith.HUSBAND, RelationTypeWith.SPOUSE, RelationTypeWith.OTHER]
		],
		// 孙辈的外祖母 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
			[RelationTypeWith.SELF, RelationTypeWith.WIFE, RelationTypeWith.SPOUSE, RelationTypeWith.OTHER]
		]
	])
] as [RelationTypeWith.GRAND_CHILDREN, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
