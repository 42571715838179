import { RelationTypeWith } from '../../types';

export const GrandFatherPartrilocal = [
	RelationTypeWith.GRAND_FATHER_PARTRILOCAL, // 主被保人与投保人是祖父关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 祖父的配偶 -> 祖母, 其他
		[RelationTypeWith.SPOUSE, [RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.GRAND_PARENT]],
		// 祖父的子女 -> 父母, 其他
		[
			RelationTypeWith.CHILD,
			[RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.OTHER]
		],
		// 祖父的父母 -> 其他
		[RelationTypeWith.PARENTS, RelationTypeWith.OTHER],
		// 祖父的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 祖父的孙辈 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.GRAND_CHILDREN, [RelationTypeWith.SIBLING, RelationTypeWith.SELF, RelationTypeWith.OTHER]],
		// 祖父的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 祖父的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 祖父的法定继承人 -> 自己, 兄弟姐妹, 子女, 孙辈, 祖辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SIBLING,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 祖父的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 祖父的妻子 -> 祖母, 其他
		[
			RelationTypeWith.WIFE,
			[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER]
		],
		// 祖父的儿子 -> 父亲, 其他
		[RelationTypeWith.SON, [RelationTypeWith.FATHER, RelationTypeWith.PARENTS, RelationTypeWith.OTHER]],
		// 祖父的女儿 -> 母亲, 其他
		[RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER],
		// 祖父的父亲 -> 其他
		[RelationTypeWith.FATHER, RelationTypeWith.OTHER],
		// 祖父的母亲 -> 其他
		[RelationTypeWith.MOTHER, RelationTypeWith.OTHER],
		// 祖父的孙子 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_SON_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖父的孙女 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖父的外孙 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.OTHER],
		// 祖父的外孙女 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 祖父的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 祖父的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 祖父的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 祖父的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.GRAND_FATHER_PARTRILOCAL, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
