import { Consts, Utils } from '../../common';
import { DateFNS } from '../../common/3rd';
import { PredefinedCheckRuleOptions } from '../../types';
import { Modeller } from '..';

export const DateRange = (options: PredefinedCheckRuleOptions): boolean => {
	let { value, params, model, root } = options;
	if (Utils.isEmpty(value)) {
		// 值为空, 不需要比较, 通过校验
		return true;
	}

	const {
		startFrom,
		endWith,
		canEqual = true,
		now = false,
		format,
		offset
	} = params;
	let relativeToValue;
	enum OperatorType {
		GreatThanEquals = 'gte',
		GreatThan = 'gt',
		LessThanEquals = 'lte',
		LessThan = 'lt'
	}
	let operator;
	if (now) {
		// 与当前日期比较
		relativeToValue = new Date();
		operator = startFrom
			? canEqual
				? OperatorType.GreatThanEquals
				: OperatorType.GreatThan
			: canEqual
			? OperatorType.LessThanEquals
			: OperatorType.LessThan;
	} else if (startFrom) {
		// 当前值跟起始值比较
		relativeToValue = Modeller.getValue({ model, root }, startFrom);
		operator = canEqual
			? OperatorType.GreatThanEquals
			: OperatorType.GreatThan;
	} else if (endWith) {
		// 当前值跟结束值比较
		relativeToValue = Modeller.getValue({ model, root }, endWith);
		operator = canEqual
			? OperatorType.LessThanEquals
			: OperatorType.LessThan;
	} else {
		console.error(options);
		throw new Error(
			'Neither start nor end declared, check definition please.'
		);
	}
	if (Utils.isEmpty(relativeToValue)) {
		// 比较值为空, 不需要比较, 通过校验
		return true;
	}

	[relativeToValue, value] = [relativeToValue, value].map(v =>
		DateFNS.format(v, format || Consts.STANDARD_DATE_FORMAT + ' 00:00:00')
	);
	if (offset) {
		relativeToValue = Utils.calculateDate(relativeToValue, offset);
	}

	switch (operator) {
		case OperatorType.GreatThan:
			return value > relativeToValue;
		case OperatorType.GreatThanEquals:
			return value >= relativeToValue;
		case OperatorType.LessThan:
			return value < relativeToValue;
		case OperatorType.LessThanEquals:
			return value <= relativeToValue;
		default:
			throw new Error(
				`Operator type[${operator}] for date range is not supported yet.`
			);
	}
};
