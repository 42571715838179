import { Utils } from '../../common';
import { PredefinedCheckRuleOptions } from '../../types';

// DC: 储蓄卡
// CC: 信用卡
// SCC: 准贷记卡
// PC: 预付费卡
// TODO 银行规则迁移
const BankCardNoRegexp = {
	// 中国邮政储蓄银行
	PSBC_DC: [
		/^(621096|621098|622150|622151|622181|622188|622199|955100|621095|620062|621285|621798|621799|621797|620529|621622|621599|621674|623218|623219)\d{13}$/,
		/^(62215049|62215050|62215051|62218850|62218851|62218849)\d{11}$/
	],
	PSBC_CC: /^(622812|622810|622811|628310|625919)\d{10}$/,
	//中国工商银行
	ICBC_DC: [
		/^(1234|620200|620302|620402|620403|620404|620406|620407|620409|620410|620411|620412|620502|620503|620405|620408|620512|620602|620604|620607|620611|620612|620704|620706|620707|620708|620709|620710|620609|620712|620713|620714|620802|620711|620904|620905|621001|620902|621103|621105|621106|621107|621102|621203|621204|621205|621206|621207|621208|621209|621210|621302|621303|621202|621305|621306|621307|621309|621311|621313|621211|621315|621304|621402|621404|621405|621406|621407|621408|621409|621410|621502|621317|621511|621602|621603|621604|621605|621608|621609|621610|621611|621612|621613|621614|621615|621616|621617|621607|621606|621804|621807|621813|621814|621817|621901|621904|621905|621906|621907|621908|621909|621910|621911|621912|621913|621915|622002|621903|622004|622005|622006|622007|622008|622010|622011|622012|621914|622015|622016|622003|622018|622019|622020|622102|622103|622104|622105|622013|622111|622114|622017|622110|622303|622304|622305|622306|622307|622308|622309|622314|622315|622317|622302|622402|622403|622404|622313|622504|622505|622509|622513|622517|622502|622604|622605|622606|622510|622703|622715|622806|622902|622903|622706|623002|623006|623008|623011|623012|622904|623015|623100|623202|623301|623400|623500|623602|623803|623901|623014|624100|624200|624301|624402|623700|624000)\d{12}$/,
		/^(622200|622202|622203|622208|621225|620058|621281|900000|621558|621559|621722|621723|620086|621226|621618|620516|621227|621288|621721|900010|623062|621670|621720|621379|621240|621724|621762|621414|621375|622926|622927|622928|622929|622930|622931|621733|621732|621372|621369|621763)\d{13}$/,
		/^(402791|427028|427038|548259|621376|621423|621428|621434|621761|621749|621300|621378|622944|622949|621371|621730|621734|621433|621370|621764|621464|621765|621750|621377|621367|621374|621731|621781)\d{10}$/,
		/^(9558)\d{15}$/
	],
	ICBC_CC: [
		/^(370246|370248|370249|370247|370267|374738|374739)\d{9}$/,
		/^(427010|427018|427019|427020|427029|427030|427039|438125|438126|451804|451810|451811|458071|489734|489735|489736|510529|427062|524091|427064|530970|530990|558360|524047|525498|622230|622231|622232|622233|622234|622235|622237|622239|622240|622245|622238|451804|451810|451811|458071|628288|628286|622206|526836|513685|543098|458441|622246|544210|548943|356879|356880|356881|356882|528856|625330|625331|625332|622236|524374|550213|625929|625927|625939|625987|625930|625114|622159|625021|625022|625932|622889|625900|625915|625916|622171|625931|625113|625928|625914|625986|625925|625921|625926|625942|622158|625917|625922|625934|625933|625920|625924|625017|625018|625019)\d{10}$/,
		/^(45806|53098|45806|53098)\d{11}$/
	],
	ICBC_SCC: /^(622210|622211|622212|622213|622214|622220|622223|622225|622229|622215|622224)\d{10}$/,
	ICBC_PC: [
		/^(620054|620142|620184|620030|620050|620143|620149|620124|620183|620094|620186|620148|620185)\d{10}$/,
		/^(620114|620187|620046)\d{13}$/
	],
	//中国农业银行
	ABC_DC:[/^(622841|622824|622826|622848|620059|621282|622828|622823|621336|621619|622821|622822|622825|622827|622845|622849|623018|623206|621671|622840|622843|622844|622846|622847|620501)\d{13}$/,
		/^(95595|95596|95597|95598|95599)\d{14}$/,
		/^(103)\d{16}$/,
	],
	//中国银行
	BOC_DC:[/^(623575|621660|621661|621662|621663|621665|621667|621668|621669|621666|456351|601382|621256|621212|621283|620061|621725|621330|621331|621332|621333|621297|621568|621569|621672|623208|621620|621756|621757|621758|621759|621785|621786|621787|621788|621789|621790|622273|622274|622771|622772|622770|621741|621041|617420)\d{13}$/,
		/^(621293|621294|621342|621343|621364|621394|621648|621248|621215|621249|621231|621638|621334|621395|623040|622348)\d{10}$/,
	],
	//中国建设银行
	CCB_DC:[
		/^(621284|436742|589970|620060|621081|621467|621598|621621|621700|622280|622700|623211|623668)\d{13}$/,
		/^(421349|434061|434062|524094|526410|552245|621080|621082|621466|621488|621499|622966|622988|622382|621487|621083|621084|620107)\d{10}$/,
		/^(436742193|622280193)\d{10}$/,
	],
	//交通银行
	COMM_DC:[
		/^(622261|622260|622262|621002|621069|621436|621335)\d{13}$/,
		/^(620013)\d{10}$/,
		/^(405512|601428|405512|601428|622258|622259|405512|601428)\d{11}$/,
	],
	//招商银行
	CMB_DC:[
		/^(402658|410062|468203|512425|524011|622580|622588|622598|622609|95555|621286|621483|621485|621486|621299)(\d{10}|\d{11})$/,
		/^(690755)\d{9}$/,	
		/^(690755)\d{12}$/,
	],
	//中国民生银行
	CMBC_DC:[
		/^(622615|622616|622618|622622|622617|622619|415599|421393|421865|427570|427571|472067|472068|622620)\d{10}$/,
	],
	//中国光大银行
	CEB_DC:[
		/^(303)\d{13}$/,
		/^(90030)\d{11}$/,
		/^(620535)\d{13}$/,
		/^(620085|622660|622662|622663|622664|622665|622666|622667|622669|622670|622671|622672|622668|622661|622674|622673|620518|621489|621492)\d{10}$/,
	],
	//中信实业银行
	CITIC_DC:[
		/^(433670|433680|442729|442730|620082|622690|622691|622692|622696|622698|622998|622999|433671|968807|968808|968809|621771|621767|621768|621770|621772|621773|622453|622456)\d{10}$/,
		/^(622459)\d{11}$/,
	],
	//兴业银行
	CIB_DC:[
		/^(438589)\d{12}$/,
		/^(90592)\d{11}$/,
		/^(966666|622909|438588|622908)\d{12}$/,
	]
} as { [propName: string]: RegExp | RegExp[] };


//银行卡校验不启用，指校验15到25位
const BankCodeMapping = {
	// '100': 'PSBC',
	// '102': 'ICBC',
	// '103': 'ABC',
	// '104': 'BOC',
	// '105': 'CCB',
	// '301': 'COMM',
	// '308':'CMB',
	// '305':'CMBC',
	// '303':'CEB',
	// '302':'CITIC',
	// '309':'CIB'
} as { [propName: string]: string };
const CardTypeMapping = {
	'1': 'DC',
	'2': 'CC',
	'3': 'SCC',
	'4': 'PC'
} as { [propName: string]: string };

export const BankCardNo = (options: PredefinedCheckRuleOptions): boolean => {
	let { value, params = {}, model } = options;
	if (Utils.isEmpty(value)) {
		// 值为空, 不需要比较, 通过校验
		return true;
	}

	const {
		cardTypePropName = 'extensionData.paymentInfo.bankCardType',
		bankCodePropName = 'extensionData.paymentInfo.bankCode',
		codeMapping
	} = params;
	const cardType = model[cardTypePropName];
	// 如果没有指定卡类型, 那么要校验所有可能性
	// 并且试图转换为系统的卡类型
	const cardTypes = (Utils.isEmpty(cardType) ? Object.keys(CardTypeMapping) : Utils.toArray(cardType)).map(
		(ct: string) => (CardTypeMapping[ct] ? CardTypeMapping[ct] : ct)
	);
	// 视图转换为系统的银行代码
	let bankCode = model[bankCodePropName];
	// 如果有指定的映射关系, 先映射一次
	bankCode = codeMapping ? codeMapping[bankCode] : bankCode;
	// 将编码映射到字母编码
	bankCode = BankCodeMapping[bankCode] ? BankCodeMapping[bankCode] : bankCode;

	return cardTypes
		.map(ct => `${bankCode}_${ct}`)
		.some(key => {
			const rules = BankCardNoRegexp[key];
			//没有找到,使用默认校验,数字15到25位
			if (!rules) {
				return /^(?=.*\d)[\d]{15,25}$/.test(value);
			}
			if (Utils.isArray(rules)) {
				return rules.some(regexp => regexp.test(value));
			} else {
				return rules.test(value);
			}
		});
};
