import { RelationTypeWith } from '../../types';

export const Sibling = [
	RelationTypeWith.SIBLING, // 主被保人与投保人是兄弟姐妹关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 兄弟姐妹的配偶
		[RelationTypeWith.SPOUSE, RelationTypeWith.OTHER],
		// 兄弟姐妹的子女
		[RelationTypeWith.CHILD, RelationTypeWith.OTHER],
		// 兄弟姐妹的父母
		[
			RelationTypeWith.PARENTS,
			[RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.MOTHER, RelationTypeWith.OTHER]
		],
		// 兄弟姐妹的(外)祖父母
		[
			RelationTypeWith.GRAND_PARENT,
			[
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 兄弟姐妹的孙辈
		[RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER],
		// 兄弟姐妹的养父母
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 兄弟姐妹的雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 兄弟姐妹的法定继承人
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 兄弟姐妹的兄弟姐妹
		[RelationTypeWith.SIBLING, [RelationTypeWith.SIBLING, RelationTypeWith.SELF]],
		// 兄弟姐妹的妻子
		[RelationTypeWith.WIFE, RelationTypeWith.OTHER],
		// 兄弟姐妹的丈夫
		[RelationTypeWith.HUSBAND, RelationTypeWith.OTHER],
		// 兄弟姐妹的儿子
		[RelationTypeWith.SON, RelationTypeWith.OTHER],
		// 兄弟姐妹的女儿
		[RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER],
		// 兄弟姐妹的父亲
		[RelationTypeWith.FATHER, RelationTypeWith.FATHER],
		// 兄弟姐妹的母亲
		[RelationTypeWith.MOTHER, RelationTypeWith.MOTHER],
		// 兄弟姐妹的孙子
		[RelationTypeWith.GRAND_SON_PARTRILOCAL, RelationTypeWith.OTHER],
		// 兄弟姐妹的孙女
		[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 兄弟姐妹的外孙
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.OTHER],
		// 兄弟姐妹的外孙女
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 兄弟姐妹的祖父
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.GRAND_FATHER_PARTRILOCAL],
		// 兄弟姐妹的祖母
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.GRAND_MOTHER_PARTRILOCAL],
		// 兄弟姐妹的外祖父
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.GRAND_FATHER_MATRIARCHAL],
		// 兄弟姐妹的外祖母
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.GRAND_MOTHER_MATRIARCHAL]
	])
] as [RelationTypeWith.SIBLING, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
