import { Consts, Utils } from '../../common';
import { DateFNS } from '../../common/3rd';
import { PredefinedCheckRuleOptions, RelationTypeWith, RelationTypeWithCheckType } from '../../types';
import { RelationNames } from '../relationship';

const alwaysPass = () => true;
const validate = (
	root: any,
	value: any,
	sourcePrefix: any,
	type: RelationTypeWithCheckType,
	relationType: RelationTypeWith,
	checker: (
		type: RelationTypeWithCheckType,
		relationType: RelationTypeWith
	) => (myValue: any, sourceValue: any) => boolean | string
): boolean | string => {
	switch (type) {
		case RelationTypeWithCheckType.AGE:
			const policyHolderAge = root[`${sourcePrefix}.extensionData.age`];
			if (policyHolderAge != null) {
				if (value.indexOf('/') !== -1) {
					value = DateFNS.diffInYears(new Date(), value);
				}
				return checker(RelationTypeWithCheckType.AGE, relationType)(value, policyHolderAge);
			} else {
				return true;
			}
		case RelationTypeWithCheckType.GENDER:
			const policyHolderGender = root[`${sourcePrefix}.gender`];
			if (policyHolderGender != null) {
				return checker(RelationTypeWithCheckType.GENDER, relationType)(value, policyHolderGender);
			} else {
				return true;
			}
		default:
			throw new Error(`Unsupported relationship with policy holder check type[${type}].`);
	}
};
const withChildChecker = (who: string) => (
	type: RelationTypeWithCheckType,
	relationType: RelationTypeWith
): ((myValue: any, sourceValue: any) => boolean | string) => {
	switch (type) {
		case RelationTypeWithCheckType.AGE:
			// 关系是子女, 年龄必须小于投保人
			return (myAge: any, sourceAge: any) =>
				myAge < sourceAge ? true : `与${who}关系为${RelationNames.get(relationType)}, 年龄必须小于${who}.`;
		case RelationTypeWithCheckType.GENDER:
			return (myGender: any) => {
				let pass = true;
				switch (relationType) {
					case RelationTypeWith.DAUGHTER:
					case RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL:
					case RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL:
						pass = myGender === Consts.GENDER.FEMALE;
						break;
					case RelationTypeWith.SON:
					case RelationTypeWith.GRAND_SON_MATRIARCHAL:
					case RelationTypeWith.GRAND_SON_PARTRILOCAL:
						pass = myGender === Consts.GENDER.MALE;
						break;
				}
				return pass ? true : `与${who}关系为${RelationNames.get(relationType)}, 性别不符.`;
			};
	}
};
const withParentsChecker = (who: string) => (
	type: RelationTypeWithCheckType,
	relationType: RelationTypeWith
): ((myValue: any, sourceValue: any) => boolean | string) => {
	switch (type) {
		case RelationTypeWithCheckType.AGE:
			return (myAge: any, sourceAge: any) =>
				myAge > sourceAge ? true : `与${who}关系为${RelationNames.get(relationType)}, 年龄必须大于${who}.`;
		case RelationTypeWithCheckType.GENDER:
			return (myGender: any) => {
				let pass = true;
				switch (relationType) {
					case RelationTypeWith.MOTHER:
					case RelationTypeWith.GRAND_MOTHER_MATRIARCHAL:
					case RelationTypeWith.GRAND_MOTHER_PARTRILOCAL:
						pass = myGender === Consts.GENDER.FEMALE;
						break;
					case RelationTypeWith.FATHER:
					case RelationTypeWith.GRAND_FATHER_MATRIARCHAL:
					case RelationTypeWith.GRAND_FATHER_PARTRILOCAL:
						pass = myGender === Consts.GENDER.MALE;
						break;
				}
				return pass ? true : `与${who}关系为${RelationNames.get(relationType)}, 性别不符.`;
			};
	}
};
const withSpouseChecker = (who: string) => (
	type: RelationTypeWithCheckType,
	relationType: RelationTypeWith
): ((myValue: any, sourceValue: any) => boolean | string) => {
	switch (type) {
		case RelationTypeWithCheckType.AGE:
			return alwaysPass;
		case RelationTypeWithCheckType.GENDER:
			// 关系是配偶, 性别必须不一样
			return (myGender: any, sourceGender: any) => {
				let pass = true;
				if (Utils.isEmpty(sourceGender)) {
					switch (relationType) {
						case RelationTypeWith.WIFE:
							pass = myGender !== Consts.GENDER.FEMALE;
							break;
						case RelationTypeWith.HUSBAND:
							pass = myGender !== Consts.GENDER.MALE;
							break;
					}
				} else {
					pass = myGender !== sourceGender;
				}
				return pass ? true : `与${who}关系为${RelationNames.get(relationType)}, 性别不符.`;
			};
	}
};

export const RelationWithPolicyHolder = (options: PredefinedCheckRuleOptions): boolean | string => {
	const { value, params = {}, model, root } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}

	const {
		relationTransformer = (value: any) => value,
		type,
		relationPropName = 'extensionData.relationWithPH'
	} = params;

	const relationType = relationTransformer(model[relationPropName]);
	if (Utils.isEmpty(relationType)) {
		return true;
	}
	switch (relationType) {
		case RelationTypeWith.SELF:
			// 自己通常不需要填写, 因此实际上并不会触发
			return true;
		case RelationTypeWith.PARENTS:
		case RelationTypeWith.FATHER:
		case RelationTypeWith.MOTHER:
		case RelationTypeWith.GRAND_PARENT:
		case RelationTypeWith.GRAND_FATHER_MATRIARCHAL:
		case RelationTypeWith.GRAND_FATHER_PARTRILOCAL:
		case RelationTypeWith.GRAND_MOTHER_MATRIARCHAL:
		case RelationTypeWith.GRAND_MOTHER_PARTRILOCAL:
			return validate(root, value, 'policyHolder', type, relationType, withParentsChecker('投保人'));
		case RelationTypeWith.CHILD:
		case RelationTypeWith.SON:
		case RelationTypeWith.SON_1:
		case RelationTypeWith.SON_99:
		case RelationTypeWith.DAUGHTER:
		case RelationTypeWith.DAUGHTER_1:
		case RelationTypeWith.DAUGHTER_99:
		case RelationTypeWith.GRAND_CHILDREN:
		case RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL:
		case RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL:
		case RelationTypeWith.GRAND_SON_MATRIARCHAL:
		case RelationTypeWith.GRAND_SON_PARTRILOCAL:
			return validate(root, value, 'policyHolder', type, relationType, withChildChecker('投保人'));
		case RelationTypeWith.SPOUSE:
		case RelationTypeWith.HUSBAND:
		case RelationTypeWith.WIFE:
			return validate(root, value, 'policyHolder', type, relationType, withSpouseChecker('投保人'));
		case RelationTypeWith.GUARDIAN:
		case RelationTypeWith.WORD:			
		case RelationTypeWith.OTHER:
			return true;
		default:
			throw new Error(`Unsupported relationship[${model[relationPropName]}].`);
	}
};
export const RelationWithPolicyHolderDuplication = (options: PredefinedCheckRuleOptions): boolean | string => {
	const { value, params = {}, root } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}

	const { relationTransformer = (value: any): any => value } = params;
	const relationType = relationTransformer(value);
	if (Utils.isEmpty(relationType)) {
		return true;
	}

	const map = new Map<RelationTypeWith, number>([
		[RelationTypeWith.SELF, 1],
		[RelationTypeWith.SPOUSE, 1],
		[RelationTypeWith.HUSBAND, 1],
		[RelationTypeWith.WIFE, 1],
		[RelationTypeWith.FATHER, 1],
		[RelationTypeWith.MOTHER, 1],
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, 1],
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, 1],
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, 1],
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, 1],
		[RelationTypeWith.PARENTS, 2],
		[RelationTypeWith.FOSTER_PARENT, 2],
		[RelationTypeWith.GRAND_PARENT, 4]
	]);
	if (map.has(relationType)) {
		const count = map.get(relationType);
		return root.insureds.filter((insured: any) =>
			(insured.participants || []).some(
				(participant: any) =>
					// eslint-disable-next-line
					participant.customerType == Consts.CUSTOMER_TYPE.INSURED &&
					// eslint-disable-next-line
					(participant.extensionData || {}).relationWithPH == value
			)
		).length > count!
			? `被保人中有超过${count}个与投保人关系是${RelationNames.get(relationType)}`
			: true;
	}
	return true;
};
export const RelationWithMainInsured = (options: PredefinedCheckRuleOptions): boolean | string => {
	const { value, params = {}, model, root } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}

	const {
		relationTransformer = (value: any) => value,
		type,
		relationPropName = 'extensionData.relationWithInsured'
	} = params;

	const relationType = relationTransformer(model[relationPropName]);
	if (Utils.isEmpty(relationType)) {
		return true;
	}
	switch (relationType) {
		case RelationTypeWith.SELF:
			// 与主被保人关系不存在本人, 不需要校验
			return true;
		case RelationTypeWith.PARENTS:
		case RelationTypeWith.FATHER:
		case RelationTypeWith.MOTHER:
		case RelationTypeWith.GRAND_PARENT:
		case RelationTypeWith.GRAND_FATHER_MATRIARCHAL:
		case RelationTypeWith.GRAND_FATHER_PARTRILOCAL:
		case RelationTypeWith.GRAND_MOTHER_MATRIARCHAL:
		case RelationTypeWith.GRAND_MOTHER_PARTRILOCAL:
			return validate(
				root,
				value,
				`insureds[!extensionData.mainInsured=Y].participants[!customerType=${Consts.CUSTOMER_TYPE.INSURED}]`,
				type,
				relationType,
				withParentsChecker('主被保人')
			);
		case RelationTypeWith.CHILD:
		case RelationTypeWith.SON:
		case RelationTypeWith.SON_1:
		case RelationTypeWith.SON_99:
		case RelationTypeWith.DAUGHTER:
		case RelationTypeWith.DAUGHTER_1:
		case RelationTypeWith.DAUGHTER_99:
		case RelationTypeWith.GRAND_CHILDREN:
		case RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL:
		case RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL:
		case RelationTypeWith.GRAND_SON_MATRIARCHAL:
		case RelationTypeWith.GRAND_SON_PARTRILOCAL:
			return validate(
				root,
				value,
				`insureds[!extensionData.mainInsured=Y].participants[!customerType=${Consts.CUSTOMER_TYPE.INSURED}]`,
				type,
				relationType,
				withChildChecker('主被保人')
			);
		case RelationTypeWith.SPOUSE:
		case RelationTypeWith.HUSBAND:
		case RelationTypeWith.WIFE:
			return validate(
				root,
				value,
				`insureds[!extensionData.mainInsured=Y].participants[!customerType=${Consts.CUSTOMER_TYPE.INSURED}]`,
				type,
				relationType,
				withSpouseChecker('主被保人')
			);
		default:
			throw new Error(`Unsupported relationship[${model[relationPropName]}].`);
	}
};
export const RelationWithMainInsuredDuplication = (options: PredefinedCheckRuleOptions): boolean | string => {
	const { value, params = {}, root } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}

	const { relationTransformer = (value: any): any => value } = params;
	const relationType = relationTransformer(value);
	if (Utils.isEmpty(relationType)) {
		return true;
	}

	const map = new Map<RelationTypeWith, number>([
		[RelationTypeWith.SELF, 1],
		[RelationTypeWith.SPOUSE, 1],
		[RelationTypeWith.HUSBAND, 1],
		[RelationTypeWith.WIFE, 1],
		[RelationTypeWith.FATHER, 1],
		[RelationTypeWith.MOTHER, 1],
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, 1],
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, 1],
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, 1],
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, 1],
		[RelationTypeWith.PARENTS, 2],
		[RelationTypeWith.FOSTER_PARENT, 2],
		[RelationTypeWith.GRAND_PARENT, 4]
	]);
	if (map.has(relationType)) {
		const count = map.get(relationType);
		return root.insureds.filter((insured: any) =>
			(insured.participants || []).some(
				(participant: any) =>
					// eslint-disable-next-line
					participant.customerType == Consts.CUSTOMER_TYPE.INSURED &&
					// eslint-disable-next-line
					(participant.extensionData || {}).relationWithInsured == value
			)
		).length > count!
			? `附加被保人中有超过${count}个与主被保人关系是${RelationNames.get(relationType)}.`
			: true;
	}
	return true;
};
/** 受益人与被保人关系校验 */
export const RelationWithInsured = (options: PredefinedCheckRuleOptions): boolean | string => {
	const { value, params = {}, model, arrayHolder } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}

	const {
		relationTransformer = (value: any) => value,
		type,
		relationPropName = 'extensionData.relationWithInsured',
		insuredPropName = `participants[!customerType=${Consts.CUSTOMER_TYPE.INSURED}]`
	} = params;

	const relationType = relationTransformer(model[relationPropName]);
	if (Utils.isEmpty(relationType)) {
		return true;
	}
	switch (relationType) {
		case RelationTypeWith.SELF:
			// 与被保人关系不存在本人, 不需要校验
			return true;
		case RelationTypeWith.PARENTS:
		case RelationTypeWith.FATHER:
		case RelationTypeWith.MOTHER:
		case RelationTypeWith.GRAND_PARENT:
		case RelationTypeWith.GRAND_FATHER_MATRIARCHAL:
		case RelationTypeWith.GRAND_FATHER_PARTRILOCAL:
		case RelationTypeWith.GRAND_MOTHER_MATRIARCHAL:
		case RelationTypeWith.GRAND_MOTHER_PARTRILOCAL:
			return validate(arrayHolder, value, insuredPropName, type, relationType, withParentsChecker('被保人'));
		case RelationTypeWith.CHILD:
		case RelationTypeWith.SON:
		case RelationTypeWith.SON_1:
		case RelationTypeWith.SON_99:
		case RelationTypeWith.DAUGHTER:
		case RelationTypeWith.DAUGHTER_1:
		case RelationTypeWith.DAUGHTER_99:
		case RelationTypeWith.GRAND_CHILDREN:
		case RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL:
		case RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL:
		case RelationTypeWith.GRAND_SON_MATRIARCHAL:
		case RelationTypeWith.GRAND_SON_PARTRILOCAL:
			return validate(arrayHolder, value, insuredPropName, type, relationType, withChildChecker('被保人'));
		case RelationTypeWith.SPOUSE:
		case RelationTypeWith.HUSBAND:
		case RelationTypeWith.WIFE:
			return validate(arrayHolder, value, insuredPropName, type, relationType, withSpouseChecker('被保人'));
		case RelationTypeWith.GUARDIAN:
		case RelationTypeWith.WORD:
		case RelationTypeWith.OTHER:
			return true;
		default:
			throw new Error(`Unsupported relationship[${model[relationPropName]}].`);
	}
};
/** 受益人与被保人关系重复校验 */
export const RelationWithInsuredDuplication = (options: PredefinedCheckRuleOptions): boolean | string => {
	const { value, params = {}, arrayHolder } = options;

	if (Utils.isEmpty(value)) {
		return true;
	}

	const { relationTransformer = (value: any): any => value } = params;
	const relationType = relationTransformer(value);
	if (Utils.isEmpty(relationType)) {
		return true;
	}

	const map = new Map<RelationTypeWith, number>([
		[RelationTypeWith.SELF, 1],
		[RelationTypeWith.SPOUSE, 1],
		[RelationTypeWith.HUSBAND, 1],
		[RelationTypeWith.WIFE, 1],
		[RelationTypeWith.FATHER, 1],
		[RelationTypeWith.MOTHER, 1],
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, 1],
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, 1],
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, 1],
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, 1],
		[RelationTypeWith.PARENTS, 2],
		[RelationTypeWith.FOSTER_PARENT, 2],
		[RelationTypeWith.GRAND_PARENT, 4]
	]);
	if (map.has(relationType)) {
		const count = map.get(relationType);
		return arrayHolder.participants.filter(
			(participant: any) =>
				// eslint-disable-next-line
				participant.customerType == Consts.CUSTOMER_TYPE.BENEFICIARY &&
				// eslint-disable-next-line
				(participant.extensionData || {}).relationWithInsured == value
		).length > count!
			? `受益人中有超过${count}个与被保人关系是${RelationNames.get(relationType)}.`
			: true;
	}
	return true;
};
