import { RelationTypeWith, Gender } from '../../types';
import { Child } from './child';
import { Daughter } from './daughter';
import { Employ } from './employ';
import { Father } from './father';
import { FosterParent } from './foster-parent';
import { GrandChildren } from './grand-children';
import { GrandParent } from './grand-parent';
import { HeirAtLaw } from './heir-at-law';
import { Husband } from './husband';
import { Mother } from './mother';
import { Parents } from './parents';
import { Sibling } from './sibling';
import { Son } from './son';
import { Spouse } from './spouse';
import { Wife } from './wife';
import { GrandSonPartrilocal } from './grand-son-partrilocal';
import { GrandDaughterPartrilocal } from './grand-daughter-partrilocal';
import { GrandSonMatriarchal } from './grand-son-matriarchal';
import { GrandDaughterMatriarchal } from './grand-daughter-matriarchal';
import { GrandFatherPartrilocal } from './grand-father-partrilocal';
import { GrandFatherMatriarchal } from './grand-father-matriarchal';
import { GrandMotherPartrilocal } from './grand-mother-partrilocal';
import { GrandMotherMatriarchal } from './grand-mother-matriarchal';

export const RelationWithPolicyHolderByRelations = new Map<
	RelationTypeWith,
	Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>
>([
	Spouse,
	Child,
	Parents,
	GrandParent,
	GrandChildren,
	FosterParent,
	Employ,
	HeirAtLaw,
	Sibling,
	Wife,
	Husband,
	Son,
	Daughter,
	Father,
	Mother,
	GrandSonPartrilocal,
	GrandDaughterPartrilocal,
	GrandSonMatriarchal,
	GrandDaughterMatriarchal,
	GrandFatherPartrilocal,
	GrandFatherMatriarchal,
	GrandMotherPartrilocal,
	GrandMotherMatriarchal
]);

export const RelationNames = new Map<RelationTypeWith, string>([
	[RelationTypeWith.SELF, '本人'],
	[RelationTypeWith.SPOUSE, '配偶'],
	[RelationTypeWith.CHILD, '子女'],
	[RelationTypeWith.PARENTS, '父母'],
	[RelationTypeWith.GRAND_PARENT, '(外)祖父母'],
	[RelationTypeWith.GRAND_CHILDREN, '孙辈'],
	[RelationTypeWith.FOSTER_PARENT, '养父母'],
	[RelationTypeWith.EMPLOY, '雇佣'],
	[RelationTypeWith.HEIR_AT_LAW, '法定继承人'],
	[RelationTypeWith.SIBLING, '兄弟姐妹'],
	[RelationTypeWith.WIFE, '妻子'],
	[RelationTypeWith.HUSBAND, '丈夫'],
	[RelationTypeWith.SON, '儿子'],
	[RelationTypeWith.SON_1, '儿子'],
	[RelationTypeWith.SON_99, '儿子'],
	[RelationTypeWith.DAUGHTER, '女儿'],
	[RelationTypeWith.DAUGHTER_1, '女儿'],
	[RelationTypeWith.DAUGHTER_99, '女儿'],
	[RelationTypeWith.FATHER, '父亲'],
	[RelationTypeWith.MOTHER, '母亲'],
	[RelationTypeWith.GRAND_SON_PARTRILOCAL, '孙子'],
	[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, '孙女'],
	[RelationTypeWith.GRAND_SON_MATRIARCHAL, '外孙'],
	[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, '外孙女'],
	[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, '祖父'],
	[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, '祖母'],
	[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, '外祖父'],
	[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, '外祖母'],
	[RelationTypeWith.GUARDIAN, '监护人'],
	[RelationTypeWith.WORD, '被监护人'],
	[RelationTypeWith.OTHER, '其他']
]);

export enum GenderByRelation {
	MALE = 2,
	FEMALE = 1,
	UNKNOWN = 0
}
// 1为女, 2为男
export const RelationGenders = new Map<RelationTypeWith, number>([
	[RelationTypeWith.SELF, GenderByRelation.UNKNOWN],
	[RelationTypeWith.SPOUSE, GenderByRelation.UNKNOWN],
	[RelationTypeWith.CHILD, GenderByRelation.UNKNOWN],
	[RelationTypeWith.PARENTS, GenderByRelation.UNKNOWN],
	[RelationTypeWith.GRAND_PARENT, GenderByRelation.UNKNOWN],
	[RelationTypeWith.GRAND_CHILDREN, GenderByRelation.UNKNOWN],
	[RelationTypeWith.FOSTER_PARENT, GenderByRelation.UNKNOWN],
	[RelationTypeWith.EMPLOY, GenderByRelation.UNKNOWN],
	[RelationTypeWith.HEIR_AT_LAW, GenderByRelation.UNKNOWN],
	[RelationTypeWith.SIBLING, GenderByRelation.UNKNOWN],
	[RelationTypeWith.WIFE, GenderByRelation.FEMALE],
	[RelationTypeWith.HUSBAND, GenderByRelation.MALE],
	[RelationTypeWith.SON, GenderByRelation.MALE],
	[RelationTypeWith.DAUGHTER, GenderByRelation.FEMALE],
	[RelationTypeWith.FATHER, GenderByRelation.MALE],
	[RelationTypeWith.MOTHER, GenderByRelation.FEMALE],
	[RelationTypeWith.GRAND_SON_PARTRILOCAL, GenderByRelation.MALE],
	[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, GenderByRelation.FEMALE],
	[RelationTypeWith.GRAND_SON_MATRIARCHAL, GenderByRelation.MALE],
	[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, GenderByRelation.FEMALE],
	[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, GenderByRelation.MALE],
	[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, GenderByRelation.FEMALE],
	[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, GenderByRelation.MALE],
	[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, GenderByRelation.FEMALE],
	[RelationTypeWith.OTHER, GenderByRelation.UNKNOWN]
]);

export const asGender = (relationGender: number): Gender | null => {
	switch (relationGender) {
		case 1:
			return Gender.FEMALE;
		case 2:
			return Gender.MALE;
		default:
			return null;
	}
};
