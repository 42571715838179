import { CheckRuleRegexpPredefinedSet } from '../../types';

export const PredefinedRegexps = {
    /** 邮箱校验正则表达式 */
    EMAIL: /^(?!.* )[a-z0-9A-Z]+([._\\-]*[a-z0-9A-Z])*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]+$/,
    // ^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$  /^[a-z0-9A-Z]+([._\\-]*[a-z0-9A-Z])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+[^\s]+$/
    /** 手机校验正则表达式 */
    MOBILE: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
    // /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    /** 出生证明 */
    BIRTH_CERTIFICATE: /^([a-zA-Z]{1})(\d){9}$/,
    /** 军官证证明 */
    MILITARY_CERTIFICATE: /^[\u2E80-\uFE4F|a-zA-Z|0-9]{10,18}$/,
    /** 士兵证证明 */
    SOLDIER_CERTIFICATE: /^[\u2E80-\uFE4F|a-zA-Z|0-9]{10,18}$/,
    /** 身份证校验正则表达式15位或18位 1800-3999 闰年不能禁止29+  */
    IDENTITY_CARD: /(^(11|12|13|14|15|21|22|23|31|32|33|34|35|36|37|41|42|43|44|45|46|50|51|52|53|54|61|62|63|64|65|71|81|82|91)(\d{4})(18|19|([23]\d))\d{2}((((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)))\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
    /** 邮编校验,对前两位校验 */
    POSTCODE: /^(0[1234567]|1[012356]|2[01234567]|3[0123456]|4[01234567]|5[1234567]|6[1234567]|7[012345]|8[013456])\d{4}$/,
    /** 中国护照校验 */
    CHINA_PASSPORT: /^1[45][0-9]{7}|([P|p|S|s]\d{7})|([S|s|G|g]\d{8})|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8})|([H|h|M|m]\d{8,10})$/,
    /** 护照 */
    PASSPORT: /^[a-zA-Z|0-9]{6,}$/,
    /** 台湾身份证 */
    TW_ID_CARD: /^(\S){1,10}$/,
    /** 台湾居民来往大陆通行证 */
    TW_MAINLAND: /^(\d){8}$/,
    /** 港澳居民来往内地通行证 */
    HK_MAINLAND: /^([H|M]{1})(\S){8}$/,
    /** 日期正则表达式1986/10/28 00:00:00 或1986-10-28 00:00:00 或1986/10/28 或 1986-10-28 */
    DATE: /^(?:(?!0000)[0-9]{4}[-/]{1}(?:(?:0[1-9]|1[0-2])[-/]{1}(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])[-/]{1}(?:29|30)|(?:0[13578]|1[02])[-/]{1}31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)[-/]{1}02[-/]{1}29)(\s(?:(?:0?|1)\d|2[0-3]):[0-5]\d:[0-5]\d){0,1}$/,
    /** 长度正则不能多于15个中文 */
    SLOGAN_LENGTH: /^[\d\W-\u4e00-\u9fffa-zA-Z]{1,15}$/,
    /** 身高校验厘米2到3位, 30-299 */
    HEIGHT_IN_CM: /^[12]\d{2}|[3-9]\d$/,
    /** 身高校验厘米2到3位, 30-299, 可以有一位小数 */
    HEIGHT_IN_CM_FRACTION: /^([12]\d{2}|[3-9]\d)(\.\d)?$/,
    /** 身高校验厘米2到3位, 30-299, 可以有两位小数 */
    HEIGHT_IN_CM_FRACTION2: /^([12]\d{2}|[3-9]\d)(\.\d{1,2})?$/,
    /** 体重校验1位到3位 */
    WEIGHT_IN_CATTY: /^([1-9]{1}[0-9]{0,2})$/,
    WEIGHT_IN_KG: /^([1-9]{1}[0-9]{0,2})$/,
    //体重保留一位有效数字
    WEIGHT_IN_KG_FRACTION: /(^[1-9]{1}[0-9]{0,2}$)|(^[1-9]{1}[0-9]{0,2}.[1-9]{1}$)/,
    //体重保留两位有效数字
    WEIGHT_IN_KG_FRACTION2: /^([1-9]\d{0,2})(\.\d{1,2})?$/,
    /** 英文2位以上校验 */
    ENGLISH_NAME: /^[\Wa-zA-Z]{2,}$/,
    DIGITS: /^\d*[0-9]\d*$/,
    FLOAT: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
    HK_IDENTITY_CARD:/^8[12]0000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/,
    TW_IDENTITY_CARD:/^830000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/
} as { readonly [propName in CheckRuleRegexpPredefinedSet]: RegExp };

export default (() => {
    return {
        find(name: CheckRuleRegexpPredefinedSet): RegExp {
            const regexp = PredefinedRegexps[name];
            if (regexp == null) {
                throw new Error(`Predefined regexp[${name}] not found.`);
            }
            return regexp;
        }
    };
})();
