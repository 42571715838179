import { RelationTypeWith } from '../../types';

export const HeirAtLaw = [
	RelationTypeWith.HEIR_AT_LAW, // 主被保人与投保人是法定继承人关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 法定继承人的配偶
		[RelationTypeWith.SPOUSE, RelationTypeWith.OTHER],
		// 法定继承人的子女
		[
			RelationTypeWith.CHILD,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的父母
		[
			RelationTypeWith.PARENTS,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的(外)祖父母
		[
			RelationTypeWith.GRAND_PARENT,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的孙辈
		[
			RelationTypeWith.GRAND_CHILDREN,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的养父母
		[
			RelationTypeWith.FOSTER_PARENT,
			[
				RelationTypeWith.FOSTER_PARENT,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.WIFE,
				RelationTypeWith.OTHER
			]
		],
		// 法定继承人的雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 法定继承人的法定继承人
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.WIFE,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的兄弟姐妹
		[
			RelationTypeWith.SIBLING,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的妻子
		[
			RelationTypeWith.WIFE,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的丈夫
		[
			RelationTypeWith.HUSBAND,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL
			]
		],
		// 法定继承人的儿子
		[
			RelationTypeWith.SON,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL
			]
		],
		// 法定继承人的女儿
		[
			RelationTypeWith.DAUGHTER,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的父亲
		[
			RelationTypeWith.FATHER,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL
			]
		],
		// 法定继承人的母亲
		[
			RelationTypeWith.MOTHER,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.WIFE,
				RelationTypeWith.PARENTS,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的孙子
		[
			RelationTypeWith.GRAND_SON_PARTRILOCAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL
			]
		],
		// 法定继承人的孙女
		[
			RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的外孙
		[
			RelationTypeWith.GRAND_SON_MATRIARCHAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL
			]
		],
		// 法定继承人的外孙女
		[
			RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.PARENTS,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的祖父
		[
			RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL
			]
		],
		// 法定继承人的祖母
		[
			RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.WIFE,
				RelationTypeWith.PARENTS,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 法定继承人的外祖父
		[
			RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL
			]
		],
		// 法定继承人的外祖母
		[
			RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.WIFE,
				RelationTypeWith.PARENTS,
				RelationTypeWith.MOTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		]
	])
] as [RelationTypeWith.HEIR_AT_LAW, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
