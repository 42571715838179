import { RelationTypeWith } from '../../types';

export const GrandSonPartrilocal = [
	RelationTypeWith.GRAND_SON_PARTRILOCAL, // 主被保人与投保人是孙子关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 孙子的配偶 -> 其他
		[RelationTypeWith.SPOUSE, RelationTypeWith.OTHER],
		// 孙子的子女 -> 其他
		[RelationTypeWith.CHILD, RelationTypeWith.OTHER],
		// 孙子的父母 -> 子女, 其他
		[
			RelationTypeWith.PARENTS,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 孙子的(外)祖父母 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_PARENT,
			[
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.WIFE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.OTHER
			]
		],
		// 孙子的孙辈 -> 其他
		[RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER],
		// 孙子的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 孙子的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 孙子的法定继承人 -> 自己, 子女, 孙辈, 祖辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.WIFE,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.MOTHER,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 孙子的兄弟姐妹 -> 孙辈, 其他
		[
			RelationTypeWith.SIBLING,
			[
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.OTHER
			]
		],
		// 孙子的妻子 -> 其他
		[RelationTypeWith.WIFE, RelationTypeWith.OTHER],
		// 孙子的儿子 -> 其他
		[RelationTypeWith.SON, RelationTypeWith.OTHER],
		// 孙子的女儿 -> 其他
		[RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER],
		// 孙子的父亲 -> 儿子, 其他
		[RelationTypeWith.FATHER, [RelationTypeWith.SON, RelationTypeWith.CHILD, RelationTypeWith.OTHER]],
		// 孙子的母亲 -> 女儿, 其他
		[RelationTypeWith.MOTHER, RelationTypeWith.OTHER],
		// 孙子的孙子 -> 其他
		[RelationTypeWith.GRAND_SON_PARTRILOCAL, RelationTypeWith.OTHER],
		// 孙子的孙女 -> 其他
		[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 孙子的外孙 -> 其他
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.OTHER],
		// 孙子的外孙女 ->  其他
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 孙子的祖父 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.HUSBAND, RelationTypeWith.SPOUSE, RelationTypeWith.OTHER]
		],
		// 孙子的祖母 -> 自己, 配偶, 其他
		[
			RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.WIFE, RelationTypeWith.SPOUSE, RelationTypeWith.OTHER]
		],
		// 孙子的外祖父 -> 自己, 配偶, 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 孙子的外祖母 -> 自己, 配偶, 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.GRAND_SON_PARTRILOCAL, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
