import { RelationTypeWith } from '../../types';

export const Son = [
	RelationTypeWith.SON, // 主被保人与投保人是儿子关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 儿子的配偶 -> 其他
		[RelationTypeWith.SPOUSE, RelationTypeWith.OTHER],
		// 儿子的子女 -> 自己的孙辈, 其他
		[
			RelationTypeWith.CHILD,
			[
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.OTHER
			]
		],
		// 儿子的父母 -> 自己, 配偶, 丈夫, 妻子, 其他
		[
			RelationTypeWith.PARENTS,
			[
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.WIFE,
				RelationTypeWith.OTHER
			]
		],
		// 儿子的(外)祖父母 -> 自己的父母, 其他
		[
			RelationTypeWith.GRAND_PARENT,
			[RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.MOTHER, RelationTypeWith.OTHER]
		],
		// 儿子的孙辈 -> 其他
		[RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER],
		// 儿子的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 儿子的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 儿子的法定继承人 -> 自己, 子女, 孙辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.SELF,
				RelationTypeWith.SPOUSE,
				RelationTypeWith.HUSBAND,
				RelationTypeWith.WIFE,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
				RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
				RelationTypeWith.GRAND_MOTHER_MATRIARCHAL
			]
		],
		// 儿子的兄弟姐妹 -> 子女, 其他
		[
			RelationTypeWith.SIBLING,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 儿子的妻子 -> 其他
		[RelationTypeWith.WIFE, RelationTypeWith.OTHER],
		// 儿子的儿子 -> 孙子, 其他
		[
			RelationTypeWith.SON,
			[
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.OTHER
			]
		],
		// 儿子的女儿 -> 孙女, 孙辈, 其他
		[
			RelationTypeWith.DAUGHTER,
			[
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.OTHER
			]
		],
		// 儿子的父亲 -> 自己, 配偶, 其他
		[
			RelationTypeWith.FATHER,
			[RelationTypeWith.SELF, RelationTypeWith.SPOUSE, RelationTypeWith.HUSBAND, RelationTypeWith.OTHER]
		],
		// 儿子的母亲 -> 自己, 配偶, 其他
		[
			RelationTypeWith.MOTHER,
			[RelationTypeWith.SELF, RelationTypeWith.SPOUSE, RelationTypeWith.WIFE, RelationTypeWith.OTHER]
		],
		// 儿子的孙子 -> 其他
		[RelationTypeWith.GRAND_SON_PARTRILOCAL, RelationTypeWith.OTHER],
		// 儿子的孙女 -> 其他
		[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 儿子的外孙 -> 其他
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.OTHER],
		// 儿子的外孙女 -> 其他
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 儿子的祖父 -> 父亲, 其他
		[
			RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
			[RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.OTHER]
		],
		// 儿子的祖母 -> 母亲, 其他
		[
			RelationTypeWith.GRAND_MOTHER_PARTRILOCAL,
			[RelationTypeWith.PARENTS, RelationTypeWith.MOTHER, RelationTypeWith.OTHER]
		],
		// 儿子的外祖父 -> 父亲, 其他
		[
			RelationTypeWith.GRAND_FATHER_MATRIARCHAL,
			[RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.OTHER]
		],
		// 儿子的外祖母 -> 母亲, 其他
		[
			RelationTypeWith.GRAND_MOTHER_MATRIARCHAL,
			[RelationTypeWith.PARENTS, RelationTypeWith.MOTHER, RelationTypeWith.OTHER]
		]
	])
] as [RelationTypeWith.SON, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
