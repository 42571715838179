import { RelationTypeWith } from '../../types';

export const FosterParent = [
	RelationTypeWith.FOSTER_PARENT, // 主被保人与投保人是养父母关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 养父母的配偶 -> 养父母
		[RelationTypeWith.SPOUSE, RelationTypeWith.FOSTER_PARENT],
		// 养父母的子女 -> 兄弟姐妹, 其他
		[RelationTypeWith.CHILD, [RelationTypeWith.SIBLING, RelationTypeWith.OTHER]],
		// 养父母的父母 -> 其他
		[RelationTypeWith.PARENTS, RelationTypeWith.OTHER],
		// 养父母的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 养父母的孙辈 -> 子女, 其他
		[
			RelationTypeWith.GRAND_CHILDREN,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 养父母的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 养父母的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 养父母的法定继承人 -> 自己, 兄弟姐妹, 子女, 孙辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.FOSTER_PARENT,
				RelationTypeWith.SELF,
				RelationTypeWith.SIBLING,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 养父母的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 养父母的妻子 -> 养父母, 其他
		[RelationTypeWith.WIFE, [RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER]],
		// 养父母的丈夫 -> 养父母, 其他
		[RelationTypeWith.HUSBAND, [RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER]],
		// 养父母的儿子 -> 兄弟姐妹, 其他
		[RelationTypeWith.SON, [RelationTypeWith.SIBLING, RelationTypeWith.OTHER, RelationTypeWith.SELF]],
		// 养父母的女儿 -> 兄弟姐妹, 其他
		[RelationTypeWith.DAUGHTER, [RelationTypeWith.SIBLING, RelationTypeWith.OTHER, RelationTypeWith.SELF]],
		// 养父母的父亲 -> 其他
		[RelationTypeWith.FATHER, RelationTypeWith.OTHER],
		// 养父母的母亲 -> 其他
		[RelationTypeWith.MOTHER, RelationTypeWith.OTHER],
		// 养父母的孙子 -> 儿子, 其他
		[
			RelationTypeWith.GRAND_SON_PARTRILOCAL,
			[RelationTypeWith.SON, RelationTypeWith.CHILD, RelationTypeWith.OTHER]
		],
		// 养父母的孙女 -> 女儿, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
			[RelationTypeWith.DAUGHTER, RelationTypeWith.CHILD, RelationTypeWith.OTHER]
		],
		// 养父母的外孙 -> 儿子, 其他
		[
			RelationTypeWith.GRAND_SON_MATRIARCHAL,
			[RelationTypeWith.SON, RelationTypeWith.CHILD, RelationTypeWith.OTHER]
		],
		// 养父母的外孙女 -> 女儿, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
			[RelationTypeWith.DAUGHTER, RelationTypeWith.CHILD, RelationTypeWith.OTHER]
		],
		// 养父母的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 养父母的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 养父母的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 养父母的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.FOSTER_PARENT, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
