import { CodeItem } from '../types';

export const CITIES = {
	'110000': [{ v: 110100, l: '市辖区' }],
	'120000': [{ v: 120100, l: '市辖区' }],
	'130000': [
		{ v: 130100, l: '石家庄市' },
		{ v: 130200, l: '唐山市' },
		{ v: 130300, l: '秦皇岛市' },
		{ v: 130400, l: '邯郸市' },
		{ v: 130500, l: '邢台市' },
		{ v: 130600, l: '保定市' },
		{ v: 130700, l: '张家口市' },
		{ v: 130800, l: '承德市' },
		{ v: 130900, l: '沧州市' },
		{ v: 131000, l: '廊坊市' },
		{ v: 131100, l: '衡水市' },
		{ v: 139000, l: '省直辖县级行政区划' }
	],
	'140000': [
		{ v: 140100, l: '太原市' },
		{ v: 140200, l: '大同市' },
		{ v: 140300, l: '阳泉市' },
		{ v: 140400, l: '长治市' },
		{ v: 140500, l: '晋城市' },
		{ v: 140600, l: '朔州市' },
		{ v: 140700, l: '晋中市' },
		{ v: 140800, l: '运城市' },
		{ v: 140900, l: '忻州市' },
		{ v: 141000, l: '临汾市' },
		{ v: 141100, l: '吕梁市' }
	],
	'150000': [
		{ v: 150100, l: '呼和浩特市' },
		{ v: 150200, l: '包头市' },
		{ v: 150300, l: '乌海市' },
		{ v: 150400, l: '赤峰市' },
		{ v: 150500, l: '通辽市' },
		{ v: 150600, l: '鄂尔多斯市' },
		{ v: 150700, l: '呼伦贝尔市' },
		{ v: 150800, l: '巴彦淖尔市' },
		{ v: 150900, l: '乌兰察布市' },
		{ v: 152200, l: '兴安盟' },
		{ v: 152500, l: '锡林郭勒盟' },
		{ v: 152900, l: '阿拉善盟' }
	],
	'210000': [
		{ v: 210100, l: '沈阳市' },
		{ v: 210200, l: '大连市' },
		{ v: 210300, l: '鞍山市' },
		{ v: 210400, l: '抚顺市' },
		{ v: 210500, l: '本溪市' },
		{ v: 210600, l: '丹东市' },
		{ v: 210700, l: '锦州市' },
		{ v: 210800, l: '营口市' },
		{ v: 210900, l: '阜新市' },
		{ v: 211000, l: '辽阳市' },
		{ v: 211100, l: '盘锦市' },
		{ v: 211200, l: '铁岭市' },
		{ v: 211300, l: '朝阳市' },
		{ v: 211400, l: '葫芦岛市' }
	],
	'220000': [
		{ v: 220100, l: '长春市' },
		{ v: 220200, l: '吉林市' },
		{ v: 220300, l: '四平市' },
		{ v: 220400, l: '辽源市' },
		{ v: 220500, l: '通化市' },
		{ v: 220600, l: '白山市' },
		{ v: 220700, l: '松原市' },
		{ v: 220800, l: '白城市' },
		{ v: 222400, l: '延边朝鲜族自治州' }
	],
	'230000': [
		{ v: 230100, l: '哈尔滨市' },
		{ v: 230200, l: '齐齐哈尔市' },
		{ v: 230300, l: '鸡西市' },
		{ v: 230400, l: '鹤岗市' },
		{ v: 230500, l: '双鸭山市' },
		{ v: 230600, l: '大庆市' },
		{ v: 230700, l: '伊春市' },
		{ v: 230800, l: '佳木斯市' },
		{ v: 230900, l: '七台河市' },
		{ v: 231000, l: '牡丹江市' },
		{ v: 231100, l: '黑河市' },
		{ v: 231200, l: '绥化市' },
		{ v: 232700, l: '大兴安岭地区' }
	],
	'310000': [{ v: 310100, l: '市辖区' }],
	'320000': [
		{ v: 320100, l: '南京市' },
		{ v: 320200, l: '无锡市' },
		{ v: 320300, l: '徐州市' },
		{ v: 320400, l: '常州市' },
		{ v: 320500, l: '苏州市' },
		{ v: 320600, l: '南通市' },
		{ v: 320700, l: '连云港市' },
		{ v: 320800, l: '淮安市' },
		{ v: 320900, l: '盐城市' },
		{ v: 321000, l: '扬州市' },
		{ v: 321100, l: '镇江市' },
		{ v: 321200, l: '泰州市' },
		{ v: 321300, l: '宿迁市' }
	],
	'330000': [
		{ v: 330100, l: '杭州市' },
		{ v: 330200, l: '宁波市' },
		{ v: 330300, l: '温州市' },
		{ v: 330400, l: '嘉兴市' },
		{ v: 330500, l: '湖州市' },
		{ v: 330600, l: '绍兴市' },
		{ v: 330700, l: '金华市' },
		{ v: 330800, l: '衢州市' },
		{ v: 330900, l: '舟山市' },
		{ v: 331000, l: '台州市' },
		{ v: 331100, l: '丽水市' }
	],
	'340000': [
		{ v: 340100, l: '合肥市' },
		{ v: 340200, l: '芜湖市' },
		{ v: 340300, l: '蚌埠市' },
		{ v: 340400, l: '淮南市' },
		{ v: 340500, l: '马鞍山市' },
		{ v: 340600, l: '淮北市' },
		{ v: 340700, l: '铜陵市' },
		{ v: 340800, l: '安庆市' },
		{ v: 341000, l: '黄山市' },
		{ v: 341100, l: '滁州市' },
		{ v: 341200, l: '阜阳市' },
		{ v: 341300, l: '宿州市' },
		{ v: 341500, l: '六安市' },
		{ v: 341600, l: '亳州市' },
		{ v: 341700, l: '池州市' },
		{ v: 341800, l: '宣城市' }
	],
	'350000': [
		{ v: 350100, l: '福州市' },
		{ v: 350200, l: '厦门市' },
		{ v: 350300, l: '莆田市' },
		{ v: 350400, l: '三明市' },
		{ v: 350500, l: '泉州市' },
		{ v: 350600, l: '漳州市' },
		{ v: 350700, l: '南平市' },
		{ v: 350800, l: '龙岩市' },
		{ v: 350900, l: '宁德市' }
	],
	'360000': [
		{ v: 360100, l: '南昌市' },
		{ v: 360200, l: '景德镇市' },
		{ v: 360300, l: '萍乡市' },
		{ v: 360400, l: '九江市' },
		{ v: 360500, l: '新余市' },
		{ v: 360600, l: '鹰潭市' },
		{ v: 360700, l: '赣州市' },
		{ v: 360800, l: '吉安市' },
		{ v: 360900, l: '宜春市' },
		{ v: 361000, l: '抚州市' },
		{ v: 361100, l: '上饶市' }
	],
	'370000': [
		{ v: 370100, l: '济南市' },
		{ v: 370200, l: '青岛市' },
		{ v: 370300, l: '淄博市' },
		{ v: 370400, l: '枣庄市' },
		{ v: 370500, l: '东营市' },
		{ v: 370600, l: '烟台市' },
		{ v: 370700, l: '潍坊市' },
		{ v: 370800, l: '济宁市' },
		{ v: 370900, l: '泰安市' },
		{ v: 371000, l: '威海市' },
		{ v: 371100, l: '日照市' },
		{ v: 371200, l: '莱芜市' },
		{ v: 371300, l: '临沂市' },
		{ v: 371400, l: '德州市' },
		{ v: 371500, l: '聊城市' },
		{ v: 371600, l: '滨州市' },
		{ v: 371700, l: '菏泽市' }
	],
	'410000': [
		{ v: 410100, l: '郑州市' },
		{ v: 410200, l: '开封市' },
		{ v: 410300, l: '洛阳市' },
		{ v: 410400, l: '平顶山市' },
		{ v: 410500, l: '安阳市' },
		{ v: 410600, l: '鹤壁市' },
		{ v: 410700, l: '新乡市' },
		{ v: 410800, l: '焦作市' },
		{ v: 410900, l: '濮阳市' },
		{ v: 411000, l: '许昌市' },
		{ v: 411100, l: '漯河市' },
		{ v: 411200, l: '三门峡市' },
		{ v: 411300, l: '南阳市' },
		{ v: 411400, l: '商丘市' },
		{ v: 411500, l: '信阳市' },
		{ v: 411600, l: '周口市' },
		{ v: 411700, l: '驻马店市' },
		{ v: 419000, l: '省直辖县级行政区划' }
	],
	'420000': [
		{ v: 420100, l: '武汉市' },
		{ v: 420200, l: '黄石市' },
		{ v: 420300, l: '十堰市' },
		{ v: 420500, l: '宜昌市' },
		{ v: 420600, l: '襄阳市' },
		{ v: 420700, l: '鄂州市' },
		{ v: 420800, l: '荆门市' },
		{ v: 420900, l: '孝感市' },
		{ v: 421000, l: '荆州市' },
		{ v: 421100, l: '黄冈市' },
		{ v: 421200, l: '咸宁市' },
		{ v: 421300, l: '随州市' },
		{ v: 422800, l: '恩施土家族苗族自治州' },
		{ v: 429000, l: '省直辖县级行政区划' }
	],
	'430000': [
		{ v: 430100, l: '长沙市' },
		{ v: 430200, l: '株洲市' },
		{ v: 430300, l: '湘潭市' },
		{ v: 430400, l: '衡阳市' },
		{ v: 430500, l: '邵阳市' },
		{ v: 430600, l: '岳阳市' },
		{ v: 430700, l: '常德市' },
		{ v: 430800, l: '张家界市' },
		{ v: 430900, l: '益阳市' },
		{ v: 431000, l: '郴州市' },
		{ v: 431100, l: '永州市' },
		{ v: 431200, l: '怀化市' },
		{ v: 431300, l: '娄底市' },
		{ v: 433100, l: '湘西土家族苗族自治州' }
	],
	'440000': [
		{ v: 440100, l: '广州市' },
		{ v: 440200, l: '韶关市' },
		{ v: 440300, l: '深圳市' },
		{ v: 440400, l: '珠海市' },
		{ v: 440500, l: '汕头市' },
		{ v: 440600, l: '佛山市' },
		{ v: 440700, l: '江门市' },
		{ v: 440800, l: '湛江市' },
		{ v: 440900, l: '茂名市' },
		{ v: 441200, l: '肇庆市' },
		{ v: 441300, l: '惠州市' },
		{ v: 441400, l: '梅州市' },
		{ v: 441500, l: '汕尾市' },
		{ v: 441600, l: '河源市' },
		{ v: 441700, l: '阳江市' },
		{ v: 441800, l: '清远市' },
		{ v: 441900, l: '东莞市' },
		{ v: 442000, l: '中山市' },
		{ v: 445100, l: '潮州市' },
		{ v: 445200, l: '揭阳市' },
		{ v: 445300, l: '云浮市' }
	],
	'450000': [
		{ v: 450100, l: '南宁市' },
		{ v: 450200, l: '柳州市' },
		{ v: 450300, l: '桂林市' },
		{ v: 450400, l: '梧州市' },
		{ v: 450500, l: '北海市' },
		{ v: 450600, l: '防城港市' },
		{ v: 450700, l: '钦州市' },
		{ v: 450800, l: '贵港市' },
		{ v: 450900, l: '玉林市' },
		{ v: 451000, l: '百色市' },
		{ v: 451100, l: '贺州市' },
		{ v: 451200, l: '河池市' },
		{ v: 451300, l: '来宾市' },
		{ v: 451400, l: '崇左市' }
	],
	'460000': [
		{ v: 460100, l: '海口市' },
		{ v: 460200, l: '三亚市' },
		{ v: 460300, l: '三沙市' },
		{ v: 460400, l: '儋州市' },
		{ v: 469000, l: '省直辖县级行政区划' }
	],
	'500000': [{ v: 500100, l: '市辖区' }, { v: 500200, l: '县' }],
	'510000': [
		{ v: 510100, l: '成都市' },
		{ v: 510300, l: '自贡市' },
		{ v: 510400, l: '攀枝花市' },
		{ v: 510500, l: '泸州市' },
		{ v: 510600, l: '德阳市' },
		{ v: 510700, l: '绵阳市' },
		{ v: 510800, l: '广元市' },
		{ v: 510900, l: '遂宁市' },
		{ v: 511000, l: '内江市' },
		{ v: 511100, l: '乐山市' },
		{ v: 511300, l: '南充市' },
		{ v: 511400, l: '眉山市' },
		{ v: 511500, l: '宜宾市' },
		{ v: 511600, l: '广安市' },
		{ v: 511700, l: '达州市' },
		{ v: 511800, l: '雅安市' },
		{ v: 511900, l: '巴中市' },
		{ v: 512000, l: '资阳市' },
		{ v: 513200, l: '阿坝藏族羌族自治州' },
		{ v: 513300, l: '甘孜藏族自治州' },
		{ v: 513400, l: '凉山彝族自治州' }
	],
	'520000': [
		{ v: 520100, l: '贵阳市' },
		{ v: 520200, l: '六盘水市' },
		{ v: 520300, l: '遵义市' },
		{ v: 520400, l: '安顺市' },
		{ v: 520500, l: '毕节市' },
		{ v: 520600, l: '铜仁市' },
		{ v: 522300, l: '黔西南布依族苗族自治州' },
		{ v: 522600, l: '黔东南苗族侗族自治州' },
		{ v: 522700, l: '黔南布依族苗族自治州' }
	],
	'530000': [
		{ v: 530100, l: '昆明市' },
		{ v: 530300, l: '曲靖市' },
		{ v: 530400, l: '玉溪市' },
		{ v: 530500, l: '保山市' },
		{ v: 530600, l: '昭通市' },
		{ v: 530700, l: '丽江市' },
		{ v: 530800, l: '普洱市' },
		{ v: 530900, l: '临沧市' },
		{ v: 532300, l: '楚雄彝族自治州' },
		{ v: 532500, l: '红河哈尼族彝族自治州' },
		{ v: 532600, l: '文山壮族苗族自治州' },
		{ v: 532800, l: '西双版纳傣族自治州' },
		{ v: 532900, l: '大理白族自治州' },
		{ v: 533100, l: '德宏傣族景颇族自治州' },
		{ v: 533300, l: '怒江傈僳族自治州' },
		{ v: 533400, l: '迪庆藏族自治州' }
	],
	'540000': [
		{ v: 540100, l: '拉萨市' },
		{ v: 540200, l: '日喀则市' },
		{ v: 540300, l: '昌都市' },
		{ v: 540400, l: '林芝市' },
		{ v: 540500, l: '山南市' },
		{ v: 542400, l: '那曲地区' },
		{ v: 542500, l: '阿里地区' }
	],
	'610000': [
		{ v: 610100, l: '西安市' },
		{ v: 610200, l: '铜川市' },
		{ v: 610300, l: '宝鸡市' },
		{ v: 610400, l: '咸阳市' },
		{ v: 610500, l: '渭南市' },
		{ v: 610600, l: '延安市' },
		{ v: 610700, l: '汉中市' },
		{ v: 610800, l: '榆林市' },
		{ v: 610900, l: '安康市' },
		{ v: 611000, l: '商洛市' }
	],
	'620000': [
		{ v: 620100, l: '兰州市' },
		{ v: 620200, l: '嘉峪关市' },
		{ v: 620300, l: '金昌市' },
		{ v: 620400, l: '白银市' },
		{ v: 620500, l: '天水市' },
		{ v: 620600, l: '武威市' },
		{ v: 620700, l: '张掖市' },
		{ v: 620800, l: '平凉市' },
		{ v: 620900, l: '酒泉市' },
		{ v: 621000, l: '庆阳市' },
		{ v: 621100, l: '定西市' },
		{ v: 621200, l: '陇南市' },
		{ v: 622900, l: '临夏回族自治州' },
		{ v: 623000, l: '甘南藏族自治州' }
	],
	'630000': [
		{ v: 630100, l: '西宁市' },
		{ v: 630200, l: '海东市' },
		{ v: 632200, l: '海北藏族自治州' },
		{ v: 632300, l: '黄南藏族自治州' },
		{ v: 632500, l: '海南藏族自治州' },
		{ v: 632600, l: '果洛藏族自治州' },
		{ v: 632700, l: '玉树藏族自治州' },
		{ v: 632800, l: '海西蒙古族藏族自治州' }
	],
	'640000': [
		{ v: 640100, l: '银川市' },
		{ v: 640200, l: '石嘴山市' },
		{ v: 640300, l: '吴忠市' },
		{ v: 640400, l: '固原市' },
		{ v: 640500, l: '中卫市' }
	],
	'650000': [
		{ v: 650100, l: '乌鲁木齐市' },
		{ v: 650200, l: '克拉玛依市' },
		{ v: 650400, l: '吐鲁番市' },
		{ v: 650500, l: '哈密市' },
		{ v: 652300, l: '昌吉回族自治州' },
		{ v: 652700, l: '博尔塔拉蒙古自治州' },
		{ v: 652800, l: '巴音郭楞蒙古自治州' },
		{ v: 652900, l: '阿克苏地区' },
		{ v: 653000, l: '克孜勒苏柯尔克孜自治州' },
		{ v: 653100, l: '喀什地区' },
		{ v: 653200, l: '和田地区' },
		{ v: 654000, l: '伊犁哈萨克自治州' },
		{ v: 654200, l: '塔城地区' },
		{ v: 654300, l: '阿勒泰地区' },
		{ v: 659000, l: '自治区直辖县级行政区划' }
	],
	'710000': [],
	'810000': [],
	'820000': []
} as { [propName: string]: CodeItem[] };
export default Object.keys(CITIES).reduce(
	(all, key) => {
		CITIES[key].forEach(item => {
			item.p = key;
			all.push(item);
		});
		return all;
	},
	[] as CodeItem[]
);
