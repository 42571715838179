import { Utils } from '../../common';
import { PredefinedCheckRuleOptions } from '../../types';
import { PredefinedRegexps } from './predefined-regexp';

// const defaultProvincePostCodePrefixMap = {
//     '110000': '10',
//     '120000': '30',
//     '130000': '(05|06|07)',
//     '140000': '(03|04)',
//     '150000': '(01|02|13|75)',
//     '210000': '(11|12)',
//     '220000': '13',
//     '230000': '(15|16)',
//     '310000': '20',
//     '320000': '(21|22)',
//     '330000': '(31|32)',
//     '340000': '(23|24)',
//     '350000': '(35|36)',
//     '360000': '(33|34)',
//     '370000': '(25|26|27)',
//     '410000': '(45|46|47)',
//     '420000': '(43|44)',
//     '430000': '(41|42)',
//     '440000': '(51|52)',
//     '450000': '(53|54)',
//     '460000': '57',
//     '500000': '40',
//     '510000': '(61|62|63|64)',
//     '520000': '(55|56)',
//     '530000': '(65|66|67)',
//     '540000': '(85|86)',
//     '610000': '(71|72)',
//     '620000': '(73|74)',
//     '630000': '81',
//     '640000': '75',
//     '650000': '(83|84)',
//     '710000': '([0-9]{1}[1-9]{1})'
// } as { [propName: string]: string };

export const PostCode = (options: PredefinedCheckRuleOptions): boolean => {
    let { model, value, params } = options;
    if (Utils.isEmpty(value) || Utils.isEmpty(params)) {
        // 值为空, 不需要比较, 通过校验
        return true;
    }
    // const { prefix, ProvincePostCodePrefixMap = defaultProvincePostCodePrefixMap } = params;
    // let provinceCode = '';
    // if (prefix) {
    // 	provinceCode = model[`${prefix}.provinceCode`];
    // } else {
    // 	provinceCode = model[`provinceCode`];
    // }
    // 不做联动校验了，因为保司地址码表走后端，无法读取国标码
    return PredefinedRegexps.POSTCODE.test(value);
    // if (Utils.isEmpty(provinceCode)) {
    // 	return PredefinedRegexps.POSTCODE.test(value);
    // } else {
    // 	// eslint-disable-next-line
    // 	return new RegExp(`^${ProvincePostCodePrefixMap[provinceCode]}\\d{4}$`).test(value);
    // }
};
