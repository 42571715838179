import { RelationTypeWith } from '../../types';

export const Wife = [
	RelationTypeWith.WIFE, // 主被保人与投保人是妻子关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 妻子的配偶 -> 自己
		[RelationTypeWith.SPOUSE, RelationTypeWith.SELF],
		// 妻子的子女 -> 自己的子女, 其他
		[
			RelationTypeWith.CHILD,
			[RelationTypeWith.CHILD, RelationTypeWith.SON, RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER]
		],
		// 妻子的父母 -> 其他
		[RelationTypeWith.PARENTS, RelationTypeWith.OTHER],
		// 妻子的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 妻子的孙辈 -> 自己的孙辈, 其他
		[
			RelationTypeWith.GRAND_CHILDREN,
			[
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_SON_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.OTHER
			]
		],
		// 妻子的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 妻子的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 妻子的法定继承人 -> 自己的法定继承人, 自己, 子女, 孙辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.HEIR_AT_LAW,
				RelationTypeWith.SELF,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 妻子的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 妻子的丈夫 -> 自己
		[RelationTypeWith.HUSBAND, RelationTypeWith.SELF],
		// 妻子的儿子 -> 儿子, 子女, 其他
		[RelationTypeWith.SON, [RelationTypeWith.SON, RelationTypeWith.CHILD, RelationTypeWith.OTHER]],
		// 妻子的女儿 -> 女儿, 子女, 其他
		[RelationTypeWith.DAUGHTER, [RelationTypeWith.DAUGHTER, RelationTypeWith.CHILD, RelationTypeWith.OTHER]],
		// 妻子的父亲 -> 其他
		[RelationTypeWith.FATHER, RelationTypeWith.OTHER],
		// 妻子的母亲 -> 其他
		[RelationTypeWith.MOTHER, RelationTypeWith.OTHER],
		// 妻子的孙子 -> 孙子, 孙辈, 其他
		[
			RelationTypeWith.GRAND_SON_PARTRILOCAL,
			[RelationTypeWith.GRAND_SON_PARTRILOCAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 妻子的孙女 -> 孙女, 孙辈, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
			[RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 妻子的外孙 -> 外孙, 孙辈, 其他
		[
			RelationTypeWith.GRAND_SON_MATRIARCHAL,
			[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 妻子的外孙女 -> 外孙女, 孙辈, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
			[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.GRAND_CHILDREN, RelationTypeWith.OTHER]
		],
		// 妻子的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 妻子的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 妻子的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 妻子的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.WIFE, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
