import { Utils } from '../../common';
import { PredefinedCheckRuleOptions } from '../../types';

const validate = (weight: any, height: any, range: string): boolean => {
	if (Utils.isEmpty(weight) || Number(weight) === 0) {
		return true;
	}
	if (Utils.isEmpty(height) || Number(height) === 0) {
		return true;
	}
	let min = 0,
		max = 100,
		includeMax = true,
		includeMin = true;
	const defs = range.match(/^(\[|\()?(\d{1,2}(\.\d)?)?,(\d{1,2}(\.\d)?)?(\]|\)?)$/);
	if (defs == null) {
		throw new Error(`Parameters[${range}] is incorrect.`);
	}
	includeMin = defs[1] !== '(';
	if (defs[2]) {
		min = parseFloat(defs[2]);
	}
	if (defs[4]) {
		max = parseFloat(defs[4]);
	}
	includeMax = defs[6] !== ')';

	try {
		const bmi = (weight / Math.pow(height, 2)) * 10000;
		if (includeMin) {
			if (bmi < min) {
				return false;
			}
		} else if (bmi <= min) {
			return false;
		}
		if (includeMax) {
			if (bmi > max) {
				return false;
			}
		} else if (bmi >= max) {
			return false;
		}
	} catch (e) {
		console.error(e);
		return true;
	}

	return true;
};
const parse = (options: PredefinedCheckRuleOptions) => {
	const { params = {}, model } = options;
	const { range = '', heightPropName = 'extensionData.height', weightPropName = 'extensionData.weight' } = params;
	const height = model[heightPropName];
	const weight = model[weightPropName];
	return { range, height, weight };
};
export const Bmi = (options: PredefinedCheckRuleOptions): boolean => {
	const params = parse(options);
	return validate(params.weight, params.height, params.range);
};
