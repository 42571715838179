export default [
	{ v: 110000, l: '北京市' },
	{ v: 120000, l: '天津市' },
	{ v: 130000, l: '河北省' },
	{ v: 140000, l: '山西省' },
	{ v: 150000, l: '内蒙古自治区' },
	{ v: 210000, l: '辽宁省' },
	{ v: 220000, l: '吉林省' },
	{ v: 230000, l: '黑龙江省' },
	{ v: 310000, l: '上海市' },
	{ v: 320000, l: '江苏省' },
	{ v: 330000, l: '浙江省' },
	{ v: 340000, l: '安徽省' },
	{ v: 350000, l: '福建省' },
	{ v: 360000, l: '江西省' },
	{ v: 370000, l: '山东省' },
	{ v: 410000, l: '河南省' },
	{ v: 420000, l: '湖北省' },
	{ v: 430000, l: '湖南省' },
	{ v: 440000, l: '广东省' },
	{ v: 450000, l: '广西壮族自治区' },
	{ v: 460000, l: '海南省' },
	{ v: 500000, l: '重庆市' },
	{ v: 510000, l: '四川省' },
	{ v: 520000, l: '贵州省' },
	{ v: 530000, l: '云南省' },
	{ v: 540000, l: '西藏自治区' },
	{ v: 610000, l: '陕西省' },
	{ v: 620000, l: '甘肃省' },
	{ v: 630000, l: '青海省' },
	{ v: 640000, l: '宁夏回族自治区' },
	{ v: 650000, l: '新疆维吾尔自治区' },
	{ v: 710000, l: '台湾省' },
	{ v: 810000, l: '香港特别行政区' },
	{ v: 820000, l: '澳门特别行政区' }
];
