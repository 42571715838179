import { Utils } from '../common';
import { ComplexFilterRule, Filterable, FilterExpressionSimple, FilterRuleFunction } from '../types';
import Modeller from './model-utils';

type RuleOptions = {
	rule: '--d' | string | FilterRuleFunction | ComplexFilterRule;
	model: any;
	root: any;
	arrayHolder?: any;
	caller: any;
	relation?: 'and' | 'or';
};
type RulesOptions = {
	rules: (string | FilterRuleFunction | ComplexFilterRule)[];
	model: any;
	root: any;
	arrayHolder?: any;
	caller: any;
	relation?: 'and' | 'or';
};
type FilterOptions = {
	filters: Filterable[];
	model: any;
	root: any;
	arrayHolder?: any;
	caller: any;
};

class Filter {
	/**
	 * 判断过滤规则是否适用.
	 */
	private static isFilterRulePositive(options: RuleOptions): boolean {
		const { root, model, arrayHolder, rule, caller } = options;

		if (rule === '--d') {
			// 预置关键字匹配, 一定会匹配上
			return true;
		} else if (Utils.isString(rule)) {
			const exp = Modeller.parseFilter(rule);
			if (Modeller.isSimpleExpression(exp) && Modeller.isFromRoot(exp.prop)) {
				// 从根模型开始匹配
				return Modeller.matchFilter(root, {
					// 去掉第一个字符, 即/符号
					prop: Modeller.getRealId(exp.prop),
					operator: exp.operator,
					value: exp.value
				});
			} else {
				// 从当前模型开始匹配
				return Modeller.matchFilter(model, exp);
			}
		} else if (Utils.isFunction(rule)) {
			// 尝试将function的this设置为caller
			// 并且将caller和options作为参数传入, 防止function有可能是一个已经绑定过this的闭包
			return (rule as FilterRuleFunction).call(caller, caller, {
				root,
				model,
				arrayHolder,
				// 这里将function本身作为参数传入没有实际意义, 只是保持统一性
				rules: [rule],
				caller
			});
		} else if (rule.and) {
			return Filter.shouldApplyFilter({
				root,
				model,
				arrayHolder,
				rules: Utils.toArray(rule.and),
				relation: 'and',
				caller
			});
		} else if (rule.or) {
			return Filter.shouldApplyFilter({
				root,
				model,
				arrayHolder,
				rules: Utils.toArray(rule.or),
				relation: 'or',
				caller
			});
		} else {
			console.error(options);
			throw new Error(`Unsupported rule type.`);
		}
	}
	/**
	 * 指定的过滤器是否可以适配
	 */
	private static shouldApplyFilter(options: RulesOptions): boolean {
		const { root, model, arrayHolder, rules, caller, relation = 'and' } = options;

		// 关系如果是and, 每个都要是true, 也就是遇到第一个false就返回
		// 关系如果是or, 遇到第一个true就返回
		switch (relation) {
			case 'and':
				return (
					rules.find(rule => {
						// 找到第一个false
						const positive = Filter.isFilterRulePositive({
							root,
							model,
							arrayHolder,
							rule,
							caller
						});
						return !positive;
					}) == null
				);
			case 'or':
				return (
					rules.find(rule => {
						// 找到第一个true
						const positive = Filter.isFilterRulePositive({
							root,
							model,
							arrayHolder,
							rule,
							caller
						});
						return positive;
					}) != null
				);
			default:
				console.log(options);
				throw new Error(`Unsupported relationship[${relation}].`);
		}
	}
	static findMatchedFilter(options: FilterOptions): Filterable | undefined {
		const { filters, model, arrayHolder, root, caller } = options;

		return filters.find((filter: Filterable) => {
			const rules = filter.on;
			if (!rules) {
				// 没有定义过滤器适用的规则, 报错
				console.error(options);
				throw new Error('There is no rule defined of filter.');
			} else if (Utils.isString(rules)) {
				// 是一个规则数组
				return Filter.shouldApplyFilter({
					root,
					model,
					arrayHolder,
					rules: [rules],
					caller
				});
			} else if (Utils.isFunction(rules)) {
				// 尝试将function的this设置为caller
				// 并且将caller和options作为参数传入, 防止function有可能是一个已经绑定过this的闭包
				return (rules as FilterRuleFunction).call(caller, caller, {
					root,
					model,
					arrayHolder,
					// 这里将function本身作为参数传入没有实际意义, 只是保持统一性
					rules: [rules],
					caller
				});
			} else if (Utils.isArray(rules)) {
				// 是一个规则数组
				return Filter.shouldApplyFilter({
					root,
					model,
					arrayHolder,
					rules,
					caller
				});
			} else if (rules.and) {
				// 是一个规则对象, 由and连接
				const subRules = rules.and;
				return Filter.shouldApplyFilter({
					root,
					model,
					arrayHolder,
					rules: Utils.toArray(subRules),
					caller,
					relation: 'and'
				});
			} else if (rules.or) {
				// 是一个规则对象, 由and连接
				const subRules = rules.or;
				return Filter.shouldApplyFilter({
					root,
					model,
					arrayHolder,
					rules: Utils.toArray(subRules),
					caller,
					relation: 'or'
				});
			} else {
				console.error(filter);
				throw new Error('Unsupported filter type.');
			}
		});
	}
	static getConcernedIdsOfFilters(options: FilterOptions): { propName: string; model: any }[] {
		const filters = Utils.toArray(options.filters);

		return (
			filters.reduce((ids, filter) => {
				const rules = filter.on;
				if (!rules) {
					// 没有定义过滤器适用的规则, 报错
					throw new Error(`There is no rule defined of filter on property[${filter}]`);
				} else {
					// 判断
					const filterIds = Filter.getConcernedIdsOfRules({
						root: options.root,
						model: options.model,
						rules: Utils.toArray(rules as any)
					});
					Array.prototype.push.apply(ids, filterIds);
				}
				return ids;
			}, []) || []
		);
	}
	private static getConcernedIdsOfRules(options: {
		root: any;
		model: any;
		rules: (string | FilterRuleFunction | ComplexFilterRule)[];
	}): { propName: string; model: any }[] {
		const root = options.root,
			model = options.model,
			rules = options.rules;

		return rules.reduce(
			(all, rule) => {
				if (rule === '--d') {
					// 适配所有, 不需要关心
				} else if (Utils.isString(rule)) {
					const exp = Modeller.parseFilter(rule);
					if ((exp as any).prop) {
						// FilterExpressionSimple
						const fes = exp as FilterExpressionSimple;
						all.push({
							propName: Modeller.getRealId(fes.prop),
							model: Modeller.isFromRoot(fes.prop) ? root : model
						});
					}
				} else if (Utils.isFunction(rule)) {
					//do nothing
				} else {
					const sub = Filter.getConcernedIdsOfRules({
						root: root,
						model: model,
						rules: Utils.toArray(rule.and || rule.or)
					});
					Array.prototype.push.apply(all, sub);
				}
				return all;
			},
			[] as { propName: string; model: any }[]
		);
	}
}

export default Filter;
