import { Consts } from '../../common';
import { CheckRuleRegexpPredefinedSet, PredefinedCheckRuleOptions } from '../../types';
import PrefefinedRegexps from './predefined-regexp';

export const IdNumber = (options: PredefinedCheckRuleOptions): boolean | string => {
    let { value, params = 'idType', model } = options;
    const idType = model[params];
    // eslint-disable-next-line
    if (idType == Consts.ID_TYPE.BIRTH_CERTIFICATE) {
        // 出生证明
        if (!PrefefinedRegexps.find(CheckRuleRegexpPredefinedSet.BIRTH_CERTIFICATE).test(value)) {
            return `请输入正确的出生证明号码.`;
        }
        return true;
    }

    if (
        // eslint-disable-next-line
    idType != Consts.ID_TYPE.ID_CARD &&
    // eslint-disable-next-line
    idType != Consts.ID_TYPE.FAMILY_REGISTER &&
    // eslint-disable-next-line
    idType != Consts.ID_TYPE.HK_RESIDENT_PERMIT &&
    // eslint-disable-next-line
    idType != Consts.ID_TYPE.TW_RESIDENT_PERMIT &&
    // eslint-disable-next-line
    idType != Consts.ID_TYPE.TEMPORARY_ID_CARD
    ) {
        // 不是身份证
        return true;
    }

    let pass = true;
    if(	// eslint-disable-next-line
    idType == Consts.ID_TYPE.HK_RESIDENT_PERMIT &&
    !PrefefinedRegexps.find(CheckRuleRegexpPredefinedSet.HK_IDENTITY_CARD).test(value)){
        pass = false;
    }else if(	// eslint-disable-next-line
    idType == Consts.ID_TYPE.TW_RESIDENT_PERMIT &&
    !PrefefinedRegexps.find(CheckRuleRegexpPredefinedSet.TW_IDENTITY_CARD).test(value)){
        pass = false;
    }else if (
        // eslint-disable-next-line
    (idType == Consts.ID_TYPE.ID_CARD ||
        // eslint-disable-next-line
        idType == Consts.ID_TYPE.FAMILY_REGISTER ||
        // eslint-disable-next-line
        idType == Consts.ID_TYPE.TEMPORARY_ID_CARD)&&
    !PrefefinedRegexps.find(CheckRuleRegexpPredefinedSet.IDENTITY_CARD).test(value)) {
        pass = false;
    } else if (value.length === 18) {
        // 18位身份证需要验证最后一位校验位
        value = value.toUpperCase();
        value = value.split('');
        //∑(ai×Wi)(mod 11)
        //加权因子
        const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
        //校验位
        const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
        let sum = 0;
        let ai = 0;
        let wi = 0;
        for (let i = 0; i < 17; i++) {
            ai = value[i];
            wi = factor[i];
            sum += ai * wi;
        }
        // eslint-disable-next-line
        if (parity[sum % 11] != value[17]) {
            pass = false;
        }
    }
    let name = '身份证';
    if(parseInt(idType) === Consts.ID_TYPE.FAMILY_REGISTER){
        name = '户口本';
    }else if(parseInt(idType) === Consts.ID_TYPE.HK_RESIDENT_PERMIT){
        name = '港澳居民居住证';
    }else if(parseInt(idType) === Consts.ID_TYPE.TW_RESIDENT_PERMIT){
        name = '台湾居民居住证';
    }else if(parseInt(idType) === Consts.ID_TYPE.TEMPORARY_ID_CARD){
        name = '临时身份证';
    }
    return pass ? true : `请输入正确的${name}号码.`;
};
