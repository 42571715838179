import { RelationTypeWith } from '../../types';

export const GrandMotherPartrilocal = [
	RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, // 主被保人与投保人是祖母关系
	new Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>([
		// 祖母的配偶 -> 祖父, 其他
		[RelationTypeWith.SPOUSE, [RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.GRAND_PARENT]],
		// 祖母的子女 -> 父母, 其他
		[
			RelationTypeWith.CHILD,
			[RelationTypeWith.PARENTS, RelationTypeWith.FATHER, RelationTypeWith.OTHER]
		],
		// 祖母的父母 -> 其他
		[RelationTypeWith.PARENTS, RelationTypeWith.OTHER],
		// 祖母的(外)祖父母 -> 其他
		[RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER],
		// 祖母的孙辈 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.GRAND_CHILDREN, [RelationTypeWith.SIBLING, RelationTypeWith.SELF, RelationTypeWith.OTHER]],
		// 祖母的养父母 -> 其他
		[RelationTypeWith.FOSTER_PARENT, RelationTypeWith.OTHER],
		// 祖母的雇员 -> 其他, 雇员
		[RelationTypeWith.EMPLOY, [RelationTypeWith.OTHER, RelationTypeWith.EMPLOY]],
		// 祖母的法定继承人 -> 自己, 兄弟姐妹, 子女, 孙辈, 祖辈, 其他
		[
			RelationTypeWith.HEIR_AT_LAW,
			[
				RelationTypeWith.OTHER,
				RelationTypeWith.PARENTS,
				RelationTypeWith.FATHER,
				RelationTypeWith.SELF,
				RelationTypeWith.SIBLING,
				RelationTypeWith.GRAND_PARENT,
				RelationTypeWith.GRAND_FATHER_PARTRILOCAL,
				RelationTypeWith.CHILD,
				RelationTypeWith.SON,
				RelationTypeWith.DAUGHTER,
				RelationTypeWith.GRAND_CHILDREN,
				RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL,
				RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
				RelationTypeWith.GRAND_SON_MATRIARCHAL,
				RelationTypeWith.GRAND_SON_PARTRILOCAL
			]
		],
		// 祖母的兄弟姐妹 -> 其他
		[RelationTypeWith.SIBLING, RelationTypeWith.OTHER],
		// 祖母的丈夫 -> 祖父, 其他
		[
			RelationTypeWith.HUSBAND,
			[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.GRAND_PARENT, RelationTypeWith.OTHER]
		],
		// 祖母的儿子 -> 父亲, 其他
		[RelationTypeWith.SON, [RelationTypeWith.FATHER, RelationTypeWith.PARENTS, RelationTypeWith.OTHER]],
		// 祖母的女儿 -> 母亲, 其他
		[RelationTypeWith.DAUGHTER, RelationTypeWith.OTHER],
		// 祖母的父亲 -> 其他
		[RelationTypeWith.FATHER, RelationTypeWith.OTHER],
		// 祖母的母亲 -> 其他
		[RelationTypeWith.MOTHER, RelationTypeWith.OTHER],
		// 祖母的孙子 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_SON_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖母的孙女 -> 自己, 兄弟姐妹, 其他
		[
			RelationTypeWith.GRAND_DAUGHTER_PARTRILOCAL,
			[RelationTypeWith.SELF, RelationTypeWith.SIBLING, RelationTypeWith.OTHER]
		],
		// 祖母的外孙 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.GRAND_SON_MATRIARCHAL, RelationTypeWith.OTHER],
		// 祖母的外孙女 -> 自己, 兄弟姐妹, 其他
		[RelationTypeWith.GRAND_DAUGHTER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 祖母的祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 祖母的祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, RelationTypeWith.OTHER],
		// 祖母的外祖父 -> 其他
		[RelationTypeWith.GRAND_FATHER_MATRIARCHAL, RelationTypeWith.OTHER],
		// 祖母的外祖母 -> 其他
		[RelationTypeWith.GRAND_MOTHER_MATRIARCHAL, RelationTypeWith.OTHER]
	])
] as [RelationTypeWith.GRAND_MOTHER_PARTRILOCAL, Map<RelationTypeWith, RelationTypeWith | RelationTypeWith[]>];
