import { Utils } from '../common';
import {
	CheckRule,
	CheckRuleFuncion,
	CheckRuleOptions,
	CheckRuleRegexpPredefinedSet,
	PredefinedCheckRuleOptions,
	PredefinedCheckRules
} from '../types';
import * as PredefinedCheckFunctions from './check-functions';

type CheckOptions = {
	rule: CheckRule;
	value: any;
	model: any;
	root: any;
	arrayHolder?: any;
	caller: any;
};
type CheckFunctionsType = { readonly [propName in PredefinedCheckRules]: CheckRuleFuncion };
type LuxuriantPredefinedCheckRuleType = {
	readonly REQUIRED: CheckRule;
	readonly EMAIL: CheckRule;
	readonly MOBILE: CheckRule;
	readonly HEIGHT_IN_CM: CheckRule;
	readonly HEIGHT_IN_CM_FRACTION: CheckRule;
	readonly HEIGHT_IN_CM_FRACTION2: CheckRule;
	readonly WEIGHT_IN_KG: CheckRule;
	readonly WEIGHT_IN_CATTY: CheckRule;
	readonly WEIGHT_IN_KG_FRACTION: CheckRule;
	readonly WEIGHT_IN_KG_FRACTION2: CheckRule;
	readonly BIRTH_WEIGHT_IN_KG: CheckRule;
	readonly GENDER_ON_RELATION: CheckRule;
	readonly DIGITS: CheckRule;
	readonly FLOAT: CheckRule;
	readonly POSTCODE: CheckRule;
	readonly TW_ID_CARD: CheckRule;
	readonly IDENTITY_CARD: CheckRule;
	readonly MILITARY_CERTIFICATE: CheckRule;
	readonly SOLDIER_CERTIFICATE: CheckRule;
	readonly TW_MAINLAND: CheckRule;
	readonly HK_MAINLAND: CheckRule;
	readonly PASSPORT: CheckRule;
	readonly NAME: CheckRule;
	readonly NAME_CHINESE_ONLY: CheckRule;
	readonly RELATION_WITH_POLICY_HOLDER_DUPLICATION: CheckRule;
	readonly RELATION_WITH_MAIN_INSURED_DUPLICATION: CheckRule;
	readonly RELATION_WITH_INSURED_DUPLICATION: CheckRule;
    readonly BIRTH_CERTIFICATE: CheckRule;
};

/** 预定义的校验规则名称（豪华版） */
const LuxuriantPredefinedCheckRules = {
	REQUIRED: {
		do: PredefinedCheckRules.REQUIRED,
		msg: '本栏位为必填项.'
	},
	EMAIL: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.EMAIL,
		msg: '请输入正确的电子邮箱.'
	},
	MOBILE: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.MOBILE,
		msg: '请输入正确的移动手机号码.'
	},
	POSTCODE: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.POSTCODE,
		msg: '请输入正确的邮政编码.'
	},
	IDENTITY_CARD: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.IDENTITY_CARD,
		msg: '请输入正确的身份证号.'
	},
	TW_ID_CARD: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.TW_ID_CARD,
		msg: '台湾身份证位数至多10位.'
	},
	MILITARY_CERTIFICATE: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.MILITARY_CERTIFICATE,
		msg: '军官证由10～18汉字英文数字组成.'
	},
	SOLDIER_CERTIFICATE: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.SOLDIER_CERTIFICATE,
		msg: '士兵证由10～18汉字英文数字组成.'
	},
	TW_MAINLAND: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.TW_MAINLAND,
		msg: '台湾居民来往大陆通行证号码为8位数字.'
	},
	HK_MAINLAND: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.HK_MAINLAND,
		msg: '港澳居民来往内地通行证号码为9位字符, 首字符为H或M.'
	},
	PASSPORT: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.PASSPORT,
		msg: '护照至少为6位字符.'
	},
	HEIGHT_IN_CM: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.HEIGHT_IN_CM,
		msg: '身高请输入整数.'
	},
	HEIGHT_IN_CM_FRACTION: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.HEIGHT_IN_CM_FRACTION,
		msg: '请输入正确的身高.'
	},
	HEIGHT_IN_CM_FRACTION2: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.HEIGHT_IN_CM_FRACTION2,
		msg: '请输入正确的身高.'
	},
	WEIGHT_IN_KG: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.WEIGHT_IN_KG,
		msg: '请输入正确的体重.'
	},
	WEIGHT_IN_CATTY: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.WEIGHT_IN_CATTY,
		msg: '请输入正确的体重.'
	},
	WEIGHT_IN_KG_FRACTION: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.WEIGHT_IN_KG_FRACTION,
		msg: '请输入正确的体重.'
	},
	WEIGHT_IN_KG_FRACTION2: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.WEIGHT_IN_KG_FRACTION2,
		msg: '请输入正确的体重.'
	},
	BIRTH_WEIGHT_IN_KG: {
		do: (options: CheckRuleOptions): boolean | string => {
			const { value } = options;
			if (!/^(([1-9]\d)|(\d))(\.\d{1,2})?$/.test(value)) {
				return '请输入正确的体重，最多保留两位有效数字.';
			}
			if (Number(value) <= 0 || Number(value)  > 10) {
				return '出生儿体重异常, 大于10千克（1千克=1公斤）, 请重新确认.';
			}
			return true;
		}
	},
	GENDER_ON_RELATION: {
		do: PredefinedCheckRules.GENDER_ON_RELATION,
		msg: '关系与性别不符.'
	},
	DIGITS: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.DIGITS,
		msg: '请输入全数字.'
	},
	FLOAT: {
		do: PredefinedCheckRules.REGEXP,
		with: CheckRuleRegexpPredefinedSet.FLOAT,
		msg: '请输入全数字.'
	},
	NAME: {
		do: PredefinedCheckRules.REGEXP,
		with: /(^[A-Za-z.\s]{4,}$)|(^[\u2E80-\uFE4F．•]{2,}$)/,
		msg: '姓名录入不符合规范.'
	},
	NAME_CHINESE_ONLY: {
		do: PredefinedCheckRules.REGEXP,
		with: /^[\u2E80-\uFE4F．•]{2,}$/,
		msg: '姓名录入不符合规范.'
	},
	RELATION_WITH_POLICY_HOLDER_DUPLICATION: {
		do: PredefinedCheckRules.RELATION_WITH_POLICY_HOLDER_DUPLICATION
	},
	RELATION_WITH_MAIN_INSURED_DUPLICATION: {
		do: PredefinedCheckRules.RELATION_WITH_MAIN_INSURED_DUPLICATION
	},
	RELATION_WITH_INSURED_DUPLICATION: {
		do: PredefinedCheckRules.RELATION_WITH_INSURED_DUPLICATION
	},
	BIRTH_CERTIFICATE: {
        do: PredefinedCheckRules.REGEXP,
        with: CheckRuleRegexpPredefinedSet.BIRTH_CERTIFICATE,
        msg: '请输入正确的出生证号.'
    },
} as LuxuriantPredefinedCheckRuleType;

const CheckFunctions = {
	required: (options: PredefinedCheckRuleOptions): boolean => {
		return !Utils.isEmpty(options.value);
	},
	regexp: PredefinedCheckFunctions.Regexp,
	dateRange: PredefinedCheckFunctions.DateRange,
	bankCardNumber: PredefinedCheckFunctions.BankCardNo,
	genderOnRelation: PredefinedCheckFunctions.GenderOnRelation,
	ageRange: PredefinedCheckFunctions.AgeRange,
	numericRange: PredefinedCheckFunctions.NumericRange,
	bmi: PredefinedCheckFunctions.Bmi,
	relationWithPolicyHolder: PredefinedCheckFunctions.RelationWithPolicyHolder,
	relationWithPolicyHolderDuplication: PredefinedCheckFunctions.RelationWithPolicyHolderDuplication,
	relationWithMainInsured: PredefinedCheckFunctions.RelationWithMainInsured,
	relationWithMainInsuredDuplication: PredefinedCheckFunctions.RelationWithMainInsuredDuplication,
	relationWithPHAndMI: PredefinedCheckFunctions.RelationWithPHAndMI,
	relationWithInsured: PredefinedCheckFunctions.RelationWithInsured,
	relationWithInsuredDuplication: PredefinedCheckFunctions.RelationWithInsuredDuplication,
	idNumber: PredefinedCheckFunctions.IdNumber,
	idNumberMatch: PredefinedCheckFunctions.IdNumberMatch,
	chinaPassport: PredefinedCheckFunctions.ChinaPassport,
	permanentResidentPermit: PredefinedCheckFunctions.PermanentResidentPermit,
	postcode: PredefinedCheckFunctions.PostCode
} as CheckFunctionsType;

/**
 * 校验器
 */
class Checker {
	static PREDEFINED = PredefinedCheckRules;
	static LUXURIANT = LuxuriantPredefinedCheckRules;
	/**
	 * 返回true通过校验, 返回false校验失败
	 */
	static check(options: CheckOptions): string | boolean {
		const { root, model, value, rule, arrayHolder, caller } = options;

		return CheckFunctions[options.rule.do as PredefinedCheckRules].call(caller, {
			root,
			model,
			value,
			rule,
			arrayHolder,
			params: (rule as any).with,
			caller
		} as CheckRuleOptions);
	}
}

export default Checker;
