import { CodeItem } from '../types';
import Checker from './check-utils';
import Filter from './filter-utils';
import Modeller from './model-utils';
import { RelationWithPolicyHolderByRelations as RelationRules, RelationNames } from './relationship';
import { BankInfo } from './bank-info';
import { DISTRICTS } from './standard-regions-china-districts';
import PROVINCES from './standard-regions-china-provinces';
import { CITIES } from './standard-regions-china-cities';

const StandardChinaRegions = import(/* webpackChunkName: "standard-china-regions" */ './standard-regions-china');
/** 假的, 没有数据的, 在没有载入真正的数据时候使用 */
const FakeStandardChinaRegions = {
	PROVINCES: [],
	CITIES: {},
	DISTRICTS: {},
	getCityList: (provinceCode: string | number): CodeItem[] => {
		return [];
	},
	getDistrictList: (cityCode: string | number): CodeItem[] => {
		return [];
	}
};

export {
	Modeller,
	Filter,
	Checker,
	DISTRICTS,
	PROVINCES,
	CITIES,
	RelationRules,
	RelationNames,
	StandardChinaRegions,
	FakeStandardChinaRegions,
	BankInfo
};

